/* tslint:disable */
/* eslint-disable */
/**
 * SteadyMD Partner API
 * The EMR API allows you to create patients, create intakes, upload identification and diagnostic files, and manage episodes of care. These endpoints are used as part of a Full Integration with SteadyMD.
 *
 * The version of the OpenAPI document: 1.1.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs } from "./base";
// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, serializeDataIfNeeded, setSearchParams, toPathString } from "./common";
import { Configuration } from "./configuration";

/**
 *
 * @export
 * @interface ClinicalNote
 */
export interface ClinicalNote {
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    episodes: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    summaryResultGuid?: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    tinkerbellSummary?: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    clinicianSummary?: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    claimedBy: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNote
     */
    updatedAt: string;
    /**
     *
     * @type {number}
     * @memberof ClinicalNote
     */
    claim?: number | null;
    /**
     *
     * @type {number}
     * @memberof ClinicalNote
     */
    createdBy: number;
    /**
     *
     * @type {Array<number>}
     * @memberof ClinicalNote
     */
    episode?: Array<number>;
}
/**
 *
 * @export
 * @interface ClinicalNoteRequest
 */
export interface ClinicalNoteRequest {
    /**
     *
     * @type {string}
     * @memberof ClinicalNoteRequest
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNoteRequest
     */
    summaryResultGuid?: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNoteRequest
     */
    tinkerbellSummary?: string;
    /**
     *
     * @type {string}
     * @memberof ClinicalNoteRequest
     */
    clinicianSummary?: string;
    /**
     *
     * @type {number}
     * @memberof ClinicalNoteRequest
     */
    claim?: number | null;
    /**
     *
     * @type {number}
     * @memberof ClinicalNoteRequest
     */
    createdBy: number;
    /**
     *
     * @type {Array<number>}
     * @memberof ClinicalNoteRequest
     */
    episode?: Array<number>;
}
/**
 *
 * @export
 * @interface Comments
 */
export interface Comments {
    /**
     *
     * @type {number}
     * @memberof Comments
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Comments
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof Comments
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof Comments
     */
    createdBy: string;
}
/**
 *
 * @export
 * @interface CommentsRequest
 */
export interface CommentsRequest {
    /**
     *
     * @type {string}
     * @memberof CommentsRequest
     */
    text: string;
}
/**
 *
 * @export
 * @interface Encounter
 */
export interface Encounter {
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    episodeGuid: string;
    /**
     *
     * @type {Array<PatientMedications>}
     * @memberof Encounter
     */
    medications: Array<PatientMedications>;
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    consultGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    completedAt?: string | null;
    /**
     * If the encounter has been canceled
     * @type {boolean}
     * @memberof Encounter
     */
    canceled?: boolean;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Encounter
     */
    diagnosis?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    treatmentPlan?: string | null;
    /**
     *
     * @type {string}
     * @memberof Encounter
     */
    notes?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Encounter
     */
    dosespotPrescriptions?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Encounter
     */
    manualPrescriptions?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Encounter
     */
    dosespotPharmacies?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Encounter
     */
    manualPharmacies?: { [key: string]: any };
    /**
     *
     * @type {number}
     * @memberof Encounter
     */
    episode: number;
    /**
     *
     * @type {number}
     * @memberof Encounter
     */
    completedBy?: number | null;
}
/**
 *
 * @export
 * @interface EncounterRequest
 */
export interface EncounterRequest {
    /**
     *
     * @type {string}
     * @memberof EncounterRequest
     */
    episodeGuid: string;
    /**
     *
     * @type {string}
     * @memberof EncounterRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EncounterRequest
     */
    completedAt?: string | null;
    /**
     * If the encounter has been canceled
     * @type {boolean}
     * @memberof EncounterRequest
     */
    canceled?: boolean;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EncounterRequest
     */
    diagnosis?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof EncounterRequest
     */
    treatmentPlan?: string | null;
    /**
     *
     * @type {string}
     * @memberof EncounterRequest
     */
    notes?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EncounterRequest
     */
    dosespotPrescriptions?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EncounterRequest
     */
    manualPrescriptions?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EncounterRequest
     */
    dosespotPharmacies?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EncounterRequest
     */
    manualPharmacies?: { [key: string]: any };
    /**
     *
     * @type {number}
     * @memberof EncounterRequest
     */
    episode: number;
    /**
     *
     * @type {number}
     * @memberof EncounterRequest
     */
    completedBy?: number | null;
}
/**
 *
 * @export
 * @interface Episode
 */
export interface Episode {
    /**
     *
     * @type {Array<Intake>}
     * @memberof Episode
     */
    intakes: Array<Intake>;
    /**
     *
     * @type {Array<LabRequisitionResponse>}
     * @memberof Episode
     */
    labRequisitions: Array<LabRequisitionResponse>;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    diagnosis?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    treatmentPlan?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    notes?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    consultGuid?: string | null;
    /**
     *
     * @type {Patient}
     * @memberof Episode
     */
    patient: Patient;
    /**
     *
     * @type {Array<EpisodePrescriptions>}
     * @memberof Episode
     */
    prescriptions: Array<EpisodePrescriptions>;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    prescribedPharmacies: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    manualPrescriptions: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    manualPharmacies: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    dosespotPrescriptions: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    dosespotPharmacies: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    bySandboxPartner: string;
    /**
     *
     * @type {Array<Patient>}
     * @memberof Episode
     */
    parentsOrGuardians: Array<Patient>;
    /**
     *
     * @type {Array<Observation>}
     * @memberof Episode
     */
    observations: Array<Observation>;
    /**
     *
     * @type {Array<HealthGorillaOrder>}
     * @memberof Episode
     */
    labOrders: Array<HealthGorillaOrder>;
    /**
     *
     * @type {Array<Comments>}
     * @memberof Episode
     */
    comments: Array<Comments>;
    /**
     *
     * @type {Array<ClinicalNote>}
     * @memberof Episode
     */
    clinicalNotes: Array<ClinicalNote>;
    /**
     *
     * @type {Array<PatientMedications>}
     * @memberof Episode
     */
    medications: Array<PatientMedications>;
    /**
     *
     * @type {Array<PatientAllergyIntolerance>}
     * @memberof Episode
     */
    allergies: Array<PatientAllergyIntolerance>;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    createdAt: string;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof Episode
     */
    requestedPharmacy?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyNpi?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyNcpdpid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyAddress1?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyAddress2?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyAddress3?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyCity?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyState?: EpisodePharmacyStateEnum;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyZipcode?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyPhone?: string | null;
    /**
     *
     * @type {string}
     * @memberof Episode
     */
    pharmacyFax?: string | null;
    /**
     *
     * @type {number}
     * @memberof Episode
     */
    pharmacyDosespotId?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum EpisodePharmacyStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface EpisodeDosespotUpdateRequest
 */
export interface EpisodeDosespotUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof EpisodeDosespotUpdateRequest
     */
    clinicianDosespotId?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeDosespotUpdateRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeDosespotUpdateRequest
     */
    clinicId: string | null;
}
/**
 *
 * @export
 * @interface EpisodeGuidOnly
 */
export interface EpisodeGuidOnly {
    /**
     *
     * @type {string}
     * @memberof EpisodeGuidOnly
     */
    guid: string;
}
/**
 *
 * @export
 * @interface EpisodePrescriptions
 */
export interface EpisodePrescriptions {
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    ndc?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    refills?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    quantity?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    daysSupply?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    directions?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    pharmacyId?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    displayName?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    writtenDate?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    effectiveDate?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    pharmacyNotes?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    dispenseUnitId?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    prescriptionId?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    prescriberDosespotId?: string;
    /**
     *
     * @type {boolean}
     * @memberof EpisodePrescriptions
     */
    noSubstitutions?: boolean;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    patientMedicationId?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodePrescriptions
     */
    dispenseUnitDescription?: string;
}
/**
 *
 * @export
 * @interface EpisodeRequest
 */
export interface EpisodeRequest {
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EpisodeRequest
     */
    diagnosis?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    treatmentPlan?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    notes?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {Array<EpisodePrescriptions>}
     * @memberof EpisodeRequest
     */
    prescriptions: Array<EpisodePrescriptions>;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EpisodeRequest
     */
    manualPrescriptions: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof EpisodeRequest
     */
    requestedPharmacy?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyNpi?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyNcpdpid?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyName?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyAddress1?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyAddress2?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyAddress3?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyCity?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyState?: EpisodeRequestPharmacyStateEnum;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyZipcode?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyPhone?: string | null;
    /**
     *
     * @type {string}
     * @memberof EpisodeRequest
     */
    pharmacyFax?: string | null;
    /**
     *
     * @type {number}
     * @memberof EpisodeRequest
     */
    pharmacyDosespotId?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum EpisodeRequestPharmacyStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface EpisodeUpdatePharmacyRequest
 */
export interface EpisodeUpdatePharmacyRequest {
    /**
     *
     * @type {Array<EpisodeUpdatePharmacyRequestManualPharmacies>}
     * @memberof EpisodeUpdatePharmacyRequest
     */
    manualPharmacies?: Array<EpisodeUpdatePharmacyRequestManualPharmacies> | null;
}
/**
 *
 * @export
 * @interface EpisodeUpdatePharmacyRequestManualPharmacies
 */
export interface EpisodeUpdatePharmacyRequestManualPharmacies {
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    ncpdpId?: string;
    /**
     *
     * @type {any}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    npiNumber?: any;
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    state?: string;
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    zipCode?: string;
    /**
     *
     * @type {any}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    address1?: any;
    /**
     *
     * @type {any}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    address2?: any;
    /**
     *
     * @type {any}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    address3?: any;
    /**
     *
     * @type {boolean}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    isDefault?: boolean;
    /**
     *
     * @type {string}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    storeName?: string;
    /**
     *
     * @type {any}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    primaryFax?: any;
    /**
     *
     * @type {boolean}
     * @memberof EpisodeUpdatePharmacyRequestManualPharmacies
     */
    isPreferred?: boolean;
}
/**
 *
 * @export
 * @interface EpisodeUpdatePrescriptionRequest
 */
export interface EpisodeUpdatePrescriptionRequest {
    /**
     *
     * @type {Array<EpisodePrescriptions>}
     * @memberof EpisodeUpdatePrescriptionRequest
     */
    manualPrescriptions?: Array<EpisodePrescriptions> | null;
}
/**
 *
 * @export
 * @interface FileAttachment
 */
export interface FileAttachment {
    /**
     *
     * @type {number}
     * @memberof FileAttachment
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    guid: string;
    /**
     *
     * @type {number}
     * @memberof FileAttachment
     */
    createdBy: number;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    kind: FileAttachmentKindEnum;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    file: string;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    externalId?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    checksum?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileAttachment
     */
    groupId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum FileAttachmentKindEnum {
    Identification = "IDENTIFICATION",
    Selfie = "SELFIE",
    Diagnostic = "DIAGNOSTIC",
    LabResult = "LAB_RESULT",
    LabOrder = "LAB_ORDER",
    Other = "OTHER",
}

/**
 *
 * @export
 * @interface HealthGorillaOrder
 */
export interface HealthGorillaOrder {
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    encounterGuid: string;
    /**
     * Laboratory ID
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    laboratory: string;
    /**
     * Use this ID within 1 hour to fetch the order details. After 1 hour, retrying the order will generate a new request result ID and create a new lab order.
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    requestResultId?: string | null;
    /**
     * External Laboratory ID for the order. This is the ID can be used to retrieve the order
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    labOrderId?: string | null;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    orderingClinician?: string | null;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    diagnosticResultId?: string | null;
    /**
     *
     * @type {Array<LabRequisitionTextOnly>}
     * @memberof HealthGorillaOrder
     */
    labRequisitions: Array<LabRequisitionTextOnly>;
    /**
     *
     * @type {Array<LabResult>}
     * @memberof HealthGorillaOrder
     */
    labResults: Array<LabResult>;
    /**
     *
     * @type {Array<FileAttachment>}
     * @memberof HealthGorillaOrder
     */
    fileAttachments: Array<FileAttachment>;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrder
     */
    createdAt: string;
}
/**
 *
 * @export
 * @interface HealthGorillaOrderRequest
 */
export interface HealthGorillaOrderRequest {
    /**
     * Laboratory ID
     * @type {string}
     * @memberof HealthGorillaOrderRequest
     */
    laboratory: string;
    /**
     * Use this ID within 1 hour to fetch the order details. After 1 hour, retrying the order will generate a new request result ID and create a new lab order.
     * @type {string}
     * @memberof HealthGorillaOrderRequest
     */
    requestResultId?: string | null;
    /**
     * External Laboratory ID for the order. This is the ID can be used to retrieve the order
     * @type {string}
     * @memberof HealthGorillaOrderRequest
     */
    labOrderId?: string | null;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrderRequest
     */
    orderingClinician?: string | null;
    /**
     *
     * @type {string}
     * @memberof HealthGorillaOrderRequest
     */
    diagnosticResultId?: string | null;
}
/**
 *
 * @export
 * @interface Intake
 */
export interface Intake {
    /**
     *
     * @type {string}
     * @memberof Intake
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Intake
     */
    createdAt: string;
    /**
     *
     * @type {Array<IntakeQuestionnaire>}
     * @memberof Intake
     */
    questionnaire: Array<IntakeQuestionnaire>;
    /**
     *
     * @type {Array<IntakeFile>}
     * @memberof Intake
     */
    files?: Array<IntakeFile>;
    /**
     *
     * @type {string}
     * @memberof Intake
     */
    previousIntakeGuid: string | null;
    /**
     *
     * @type {string}
     * @memberof Intake
     */
    labRequisitionGuid?: string | null;
}
/**
 *
 * @export
 * @interface IntakeAnswerOption
 */
export interface IntakeAnswerOption {
    /**
     *
     * @type {string}
     * @memberof IntakeAnswerOption
     */
    valueString?: string;
}
/**
 *
 * @export
 * @interface IntakeFile
 */
export interface IntakeFile {
    /**
     *
     * @type {string}
     * @memberof IntakeFile
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof IntakeFile
     */
    externalId?: string | null;
    /**
     * Additional data about the file source
     * @type {{ [key: string]: any; }}
     * @memberof IntakeFile
     */
    sourceInfo?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof IntakeFile
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof IntakeFile
     */
    file: string;
    /**
     *
     * @type {string}
     * @memberof IntakeFile
     */
    kind: IntakeFileKindEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum IntakeFileKindEnum {
    Identification = "identification",
    Selfie = "selfie",
    Diagnostic = "diagnostic",
    LabResult = "lab_result",
    Other = "other",
}

/**
 *
 * @export
 * @interface IntakeFileCreate
 */
export interface IntakeFileCreate {
    /**
     *
     * @type {string}
     * @memberof IntakeFileCreate
     */
    intakeGuid: string;
    /**
     *
     * @type {string}
     * @memberof IntakeFileCreate
     */
    externalId?: string | null;
    /**
     * Additional data about the file source
     * @type {{ [key: string]: any; }}
     * @memberof IntakeFileCreate
     */
    sourceInfo?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof IntakeFileCreate
     */
    file: string;
    /**
     *
     * @type {string}
     * @memberof IntakeFileCreate
     */
    kind: IntakeFileCreateKindEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum IntakeFileCreateKindEnum {
    Identification = "identification",
    Selfie = "selfie",
    Diagnostic = "diagnostic",
    LabResult = "lab_result",
    Other = "other",
}

/**
 *
 * @export
 * @interface IntakeFileRequest
 */
export interface IntakeFileRequest {
    /**
     *
     * @type {string}
     * @memberof IntakeFileRequest
     */
    externalId?: string | null;
    /**
     * Additional data about the file source
     * @type {{ [key: string]: any; }}
     * @memberof IntakeFileRequest
     */
    sourceInfo?: { [key: string]: any } | null;
    /**
     *
     * @type {any}
     * @memberof IntakeFileRequest
     */
    file: any;
    /**
     *
     * @type {string}
     * @memberof IntakeFileRequest
     */
    kind: IntakeFileRequestKindEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum IntakeFileRequestKindEnum {
    Identification = "identification",
    Selfie = "selfie",
    Diagnostic = "diagnostic",
    LabResult = "lab_result",
    Other = "other",
}

/**
 *
 * @export
 * @interface IntakeQuestionnaire
 */
export interface IntakeQuestionnaire {
    /**
     *
     * @type {string}
     * @memberof IntakeQuestionnaire
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof IntakeQuestionnaire
     */
    question: string;
    /**
     *
     * @type {Array<string | object>}
     * @memberof IntakeQuestionnaire
     */
    answer: Array<string | object>;
    /**
     * question types
     * @type {string}
     * @memberof IntakeQuestionnaire
     */
    type: IntakeQuestionnaireTypeEnum;
    /**
     *
     * @type {string}
     * @memberof IntakeQuestionnaire
     */
    answerType?: IntakeQuestionnaireAnswerTypeEnum;
    /**
     *
     * @type {Array<IntakeAnswerOption>}
     * @memberof IntakeQuestionnaire
     */
    answerOption?: Array<IntakeAnswerOption>;
}

/**
 * @export
 * @enum {string}
 */
export enum IntakeQuestionnaireTypeEnum {
    Attachment = "attachment",
    Choice = "choice",
    OpenChoice = "open-choice",
    Text = "text",
}
/**
 * @export
 * @enum {string}
 */
export enum IntakeQuestionnaireAnswerTypeEnum {
    ValueString = "valueString",
    ValueBoolean = "valueBoolean",
    ValueAttachment = "valueAttachment",
}

/**
 *
 * @export
 * @interface IntakeRequest
 */
export interface IntakeRequest {
    /**
     *
     * @type {Array<IntakeQuestionnaire>}
     * @memberof IntakeRequest
     */
    questionnaire: Array<IntakeQuestionnaire>;
    /**
     *
     * @type {Array<IntakeFileRequest>}
     * @memberof IntakeRequest
     */
    files?: Array<IntakeFileRequest>;
    /**
     *
     * @type {string}
     * @memberof IntakeRequest
     */
    labRequisitionGuid?: string | null;
}
/**
 *
 * @export
 * @interface LabDispositionUpdateRequest
 */
export interface LabDispositionUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof LabDispositionUpdateRequest
     */
    consultGuid: string;
    /**
     *
     * @type {string}
     * @memberof LabDispositionUpdateRequest
     */
    notes?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabDispositionUpdateRequest
     */
    npiNumber: string;
    /**
     *
     * @type {string}
     * @memberof LabDispositionUpdateRequest
     */
    consultType: string;
}
/**
 *
 * @export
 * @interface LabInterpretation
 */
export interface LabInterpretation {
    /**
     *
     * @type {string}
     * @memberof LabInterpretation
     */
    guid: string;
    /**
     * SteadyMD only. The source of the interpretation
     * @type {string}
     * @memberof LabInterpretation
     */
    source?: LabInterpretationSourceEnum;
    /**
     * HL7 code for the interpretation, <a href=\"https://terminology.hl7.org/5.1.0/CodeSystem-v3-ObservationInterpretation.html\">HL7 LINK</a> has the list of codes
     * @type {string}
     * @memberof LabInterpretation
     */
    coding?: string | null;
    /**
     * Display. Short description of the interpretation
     * @type {string}
     * @memberof LabInterpretation
     */
    text: string;
    /**
     * Description of the interpretation
     * @type {string}
     * @memberof LabInterpretation
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabInterpretation
     */
    smdPriority?: LabInterpretationSmdPriorityEnum;
    /**
     * SteadyMD only. The clinician who created this interpretation. If null, it means it was interpreted by the system
     * @type {string}
     * @memberof LabInterpretation
     */
    clinicianGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabInterpretation
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof LabInterpretation
     */
    updatedAt: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LabInterpretationSourceEnum {
    Laboratory = "laboratory",
    System = "system",
    Clinician = "clinician",
}
/**
 * @export
 * @enum {string}
 */
export enum LabInterpretationSmdPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface LabInterpretationRequest
 */
export interface LabInterpretationRequest {
    /**
     * SteadyMD only. The source of the interpretation
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    source?: LabInterpretationRequestSourceEnum;
    /**
     * HL7 code for the interpretation, <a href=\"https://terminology.hl7.org/5.1.0/CodeSystem-v3-ObservationInterpretation.html\">HL7 LINK</a> has the list of codes
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    coding?: string | null;
    /**
     * Display. Short description of the interpretation
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    text: string;
    /**
     * Description of the interpretation
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    smdPriority?: LabInterpretationRequestSmdPriorityEnum;
    /**
     * SteadyMD only. The clinician who created this interpretation. If null, it means it was interpreted by the system
     * @type {string}
     * @memberof LabInterpretationRequest
     */
    clinicianGuid?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabInterpretationRequestSourceEnum {
    Laboratory = "laboratory",
    System = "system",
    Clinician = "clinician",
}
/**
 * @export
 * @enum {string}
 */
export enum LabInterpretationRequestSmdPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface LabOrder
 */
export interface LabOrder {
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    emrUri: string;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    episodeGuid: string;
    /**
     * Laboratory ID
     * @type {string}
     * @memberof LabOrder
     */
    laboratory: string;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    paymentResponsibility?: LabOrderPaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    submissionMethod?: LabOrderSubmissionMethodEnum;
    /**
     * Use this ID within 1 hour to fetch the order details. After 1 hour, retrying the order will generate a new request result ID and create a new lab order.
     * @type {string}
     * @memberof LabOrder
     */
    requestResultId?: string | null;
    /**
     * External Laboratory ID for the order. This is the ID can be used to retrieve the order
     * @type {string}
     * @memberof LabOrder
     */
    labOrderId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    diagnosticResultId?: string | null;
    /**
     * For partners that have multiple lab accounts, this field is used to specify which account to use.
     * @type {string}
     * @memberof LabOrder
     */
    labAccountId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    orderingClinician?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrder
     */
    labResults: string;
    /**
     *
     * @type {Array<LabRequisitionShort>}
     * @memberof LabOrder
     */
    labRequisitions: Array<LabRequisitionShort>;
    /**
     * The reference ID which the lab collection site uses to identify the order
     * @type {string}
     * @memberof LabOrder
     */
    labReferenceId?: string | null;
    /**
     *
     * @type {LabOrderStatus}
     * @memberof LabOrder
     */
    status: LabOrderStatus;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof LabOrder
     */
    labApprovedCriteria?: { [key: string]: any } | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabOrderPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum LabOrderSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface LabOrderRequest
 */
export interface LabOrderRequest {
    /**
     *
     * @type {string}
     * @memberof LabOrderRequest
     */
    episodeGuid: string;
    /**
     * Laboratory ID
     * @type {string}
     * @memberof LabOrderRequest
     */
    laboratory: string;
    /**
     *
     * @type {string}
     * @memberof LabOrderRequest
     */
    paymentResponsibility?: LabOrderRequestPaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof LabOrderRequest
     */
    submissionMethod?: LabOrderRequestSubmissionMethodEnum;
    /**
     * Use this ID within 1 hour to fetch the order details. After 1 hour, retrying the order will generate a new request result ID and create a new lab order.
     * @type {string}
     * @memberof LabOrderRequest
     */
    requestResultId?: string | null;
    /**
     * External Laboratory ID for the order. This is the ID can be used to retrieve the order
     * @type {string}
     * @memberof LabOrderRequest
     */
    labOrderId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderRequest
     */
    diagnosticResultId?: string | null;
    /**
     * For partners that have multiple lab accounts, this field is used to specify which account to use.
     * @type {string}
     * @memberof LabOrderRequest
     */
    labAccountId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderRequest
     */
    orderingClinician?: string | null;
    /**
     *
     * @type {Array<LabRequisitionShortRequest>}
     * @memberof LabOrderRequest
     */
    labRequisitions: Array<LabRequisitionShortRequest>;
    /**
     * The reference ID which the lab collection site uses to identify the order
     * @type {string}
     * @memberof LabOrderRequest
     */
    labReferenceId?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof LabOrderRequest
     */
    labApprovedCriteria?: { [key: string]: any } | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabOrderRequestPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum LabOrderRequestSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface LabOrderStatus
 */
export interface LabOrderStatus {
    /**
     *
     * @type {string}
     * @memberof LabOrderStatus
     */
    status?: LabOrderStatusStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatus
     */
    description?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof LabOrderStatus
     */
    jsonData?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatus
     */
    consultGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatus
     */
    statusCode?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabOrderStatusStatusEnum {
    Received = "received",
    Submitted = "submitted",
    Resubmitted = "resubmitted",
    RetryingSubmission = "retrying_submission",
    WaitingForResult = "waiting_for_result",
    ResultReceived = "result_received",
    ResultReleased = "result_released",
    Failed = "failed",
    Rejected = "rejected",
}

/**
 *
 * @export
 * @interface LabOrderStatusRequest
 */
export interface LabOrderStatusRequest {
    /**
     *
     * @type {string}
     * @memberof LabOrderStatusRequest
     */
    status?: LabOrderStatusRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatusRequest
     */
    description?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof LabOrderStatusRequest
     */
    jsonData?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatusRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabOrderStatusRequest
     */
    statusCode?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabOrderStatusRequestStatusEnum {
    Received = "received",
    Submitted = "submitted",
    Resubmitted = "resubmitted",
    RetryingSubmission = "retrying_submission",
    WaitingForResult = "waiting_for_result",
    ResultReceived = "result_received",
    ResultReleased = "result_released",
    Failed = "failed",
    Rejected = "rejected",
}

/**
 *
 * @export
 * @interface LabReferenceRange
 */
export interface LabReferenceRange {
    /**
     *
     * @type {number}
     * @memberof LabReferenceRange
     */
    low?: number | null;
    /**
     *
     * @type {number}
     * @memberof LabReferenceRange
     */
    high?: number | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRange
     */
    text?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRange
     */
    unit?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRange
     */
    system?: string | null;
}
/**
 *
 * @export
 * @interface LabReferenceRangeRequest
 */
export interface LabReferenceRangeRequest {
    /**
     *
     * @type {number}
     * @memberof LabReferenceRangeRequest
     */
    low?: number | null;
    /**
     *
     * @type {number}
     * @memberof LabReferenceRangeRequest
     */
    high?: number | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRangeRequest
     */
    text?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRangeRequest
     */
    unit?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabReferenceRangeRequest
     */
    system?: string | null;
}
/**
 *
 * @export
 * @interface LabRequisitionCreateRequest
 */
export interface LabRequisitionCreateRequest {
    /**
     *
     * @type {string}
     * @memberof LabRequisitionCreateRequest
     */
    episodeGuid: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionCreateRequest
     */
    laboratory: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionCreateRequest
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionCreateRequest
     */
    labOrderName: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionCreateRequest
     */
    paymentResponsibility?: LabRequisitionCreateRequestPaymentResponsibilityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LabRequisitionCreateRequestPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}

/**
 *
 * @export
 * @interface LabRequisitionResponse
 */
export interface LabRequisitionResponse {
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    episodeGuid: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    episodeUri: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labRequisitionGuid: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    laboratory: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labOrderName: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    paymentResponsibility?: LabRequisitionResponsePaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    submissionMethod: LabRequisitionResponseSubmissionMethodEnum;
    /**
     * Deprecated - Use LabOrder.labAccountId. For partners that have multiple lab accounts,  this field is used to specify which account to use.
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labAccountId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    diagnosticResultId: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labOrderId: string;
    /**
     *
     * @type {Array<LabOrder>}
     * @memberof LabRequisitionResponse
     */
    orders?: Array<LabOrder>;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    labResultData: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponse
     */
    intakeGuid: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LabRequisitionResponsePaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum LabRequisitionResponseSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface LabRequisitionResponseRequest
 */
export interface LabRequisitionResponseRequest {
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    laboratory: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    labOrderName: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    paymentResponsibility?: LabRequisitionResponseRequestPaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    submissionMethod: LabRequisitionResponseRequestSubmissionMethodEnum;
    /**
     * Deprecated - Use LabOrder.labAccountId. For partners that have multiple lab accounts,  this field is used to specify which account to use.
     * @type {string}
     * @memberof LabRequisitionResponseRequest
     */
    labAccountId?: string | null;
    /**
     *
     * @type {Array<LabOrderRequest>}
     * @memberof LabRequisitionResponseRequest
     */
    orders?: Array<LabOrderRequest>;
}

/**
 * @export
 * @enum {string}
 */
export enum LabRequisitionResponseRequestPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum LabRequisitionResponseRequestSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface LabRequisitionShort
 */
export interface LabRequisitionShort {
    /**
     *
     * @type {string}
     * @memberof LabRequisitionShort
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof LabRequisitionShort
     */
    episodeUri: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionShort
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionShort
     */
    labOrderName: string;
}
/**
 *
 * @export
 * @interface LabRequisitionShortRequest
 */
export interface LabRequisitionShortRequest {
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionShortRequest
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionShortRequest
     */
    labOrderName: string;
}
/**
 *
 * @export
 * @interface LabRequisitionTextOnly
 */
export interface LabRequisitionTextOnly {
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionTextOnly
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionTextOnly
     */
    labOrderName: string;
}
/**
 *
 * @export
 * @interface LabRequisitionTextOnlyRequest
 */
export interface LabRequisitionTextOnlyRequest {
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabRequisitionTextOnlyRequest
     */
    labOrderCode: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof LabRequisitionTextOnlyRequest
     */
    labOrderName: string;
}
/**
 *
 * @export
 * @interface LabResult
 */
export interface LabResult {
    /**
     *
     * @type {number}
     * @memberof LabResult
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    labOrderGuid: string;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    labOrderId: string;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    intakeGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    status?: LabResultStatusEnum;
    /**
     * External Identifier for the Lab result. Lab results cannot be updated. Use this field to identify the same Lab results across different versions.
     * @type {string}
     * @memberof LabResult
     */
    externalId: string | null;
    /**
     * Version number of the LabResult. Use this field to store multiple versions of the same LabResult
     * @type {string}
     * @memberof LabResult
     */
    version: string | null;
    /**
     * HL7 code for the interpretation, <a href=\"https://terminology.hl7.org/5.1.0/CodeSystem-v3-ObservationInterpretation.html\">HL7 LINK</a> has the list of codes
     * @type {string}
     * @memberof LabResult
     */
    abnormalFlag?: string | null;
    /**
     * Lab Result Priority
     * @type {string}
     * @memberof LabResult
     */
    priority?: LabResultPriorityEnum;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    accessionNumber?: string | null;
    /**
     *
     * @type {Array<LabResultObservation>}
     * @memberof LabResult
     */
    observations?: Array<LabResultObservation> | null;
    /**
     *
     * @type {Array<PlaceOfService>}
     * @memberof LabResult
     */
    placesOfService?: Array<PlaceOfService> | null;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    conclusion?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResult
     */
    createdAt: string;
    /**
     * Date and time when the results were released
     * @type {string}
     * @memberof LabResult
     */
    releasedAt?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabResultStatusEnum {
    Registered = "registered",
    Partial = "partial",
    Preliminary = "preliminary",
    Final = "final",
    Corrected = "corrected",
    Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface LabResultObservation
 */
export interface LabResultObservation {
    /**
     *
     * @type {number}
     * @memberof LabResultObservation
     */
    id: number;
    /**
     *
     * @type {Array<LabInterpretation>}
     * @memberof LabResultObservation
     */
    interpretations: Array<LabInterpretation>;
    /**
     *
     * @type {Array<LabReferenceRange>}
     * @memberof LabResultObservation
     */
    referenceRanges: Array<LabReferenceRange>;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    guid: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabResultObservation
     */
    labOrderCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    labOrderName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    status?: LabResultObservationStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    code?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    codeText?: string | null;
    /**
     * Clinically relevant time/time-period for observation
     * @type {string}
     * @memberof LabResultObservation
     */
    effectiveAt?: string | null;
    /**
     * Date and Time this version was made available
     * @type {string}
     * @memberof LabResultObservation
     */
    issuedAt?: string | null;
    /**
     * Date and time the specimen was collected
     * @type {string}
     * @memberof LabResultObservation
     */
    collectedAt?: string | null;
    /**
     * The time when specimen is received by the testing laboratory
     * @type {string}
     * @memberof LabResultObservation
     */
    receivedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    value?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    unit?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    system?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    placeOfServiceCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    reportComments?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    smdAbnormalFlag?: LabResultObservationSmdAbnormalFlagEnum;
    /**
     *
     * @type {string}
     * @memberof LabResultObservation
     */
    smdPriority?: LabResultObservationSmdPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof LabResultObservation
     */
    labRequisition?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationStatusEnum {
    Registered = "registered",
    Preliminary = "preliminary",
    Final = "final",
    Amended = "amended",
    Cancelled = "cancelled",
    Corrected = "corrected",
    EnteredInError = "entered-in-error",
    Unknown = "unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationSmdAbnormalFlagEnum {
    N = "N",
    A = "A",
    NotApplicable = "not_applicable",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationSmdPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
}

/**
 *
 * @export
 * @interface LabResultObservationRequest
 */
export interface LabResultObservationRequest {
    /**
     *
     * @type {Array<LabInterpretationRequest>}
     * @memberof LabResultObservationRequest
     */
    interpretations: Array<LabInterpretationRequest>;
    /**
     *
     * @type {Array<LabReferenceRangeRequest>}
     * @memberof LabResultObservationRequest
     */
    referenceRanges: Array<LabReferenceRangeRequest>;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    labOrderCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    labOrderName?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    status?: LabResultObservationRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    code?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    codeText?: string | null;
    /**
     * Clinically relevant time/time-period for observation
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    effectiveAt?: string | null;
    /**
     * Date and Time this version was made available
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    issuedAt?: string | null;
    /**
     * Date and time the specimen was collected
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    collectedAt?: string | null;
    /**
     * The time when specimen is received by the testing laboratory
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    receivedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    value?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    unit?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    system?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    placeOfServiceCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    reportComments?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    smdAbnormalFlag?: LabResultObservationRequestSmdAbnormalFlagEnum;
    /**
     *
     * @type {string}
     * @memberof LabResultObservationRequest
     */
    smdPriority?: LabResultObservationRequestSmdPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof LabResultObservationRequest
     */
    labRequisition?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationRequestStatusEnum {
    Registered = "registered",
    Preliminary = "preliminary",
    Final = "final",
    Amended = "amended",
    Cancelled = "cancelled",
    Corrected = "corrected",
    EnteredInError = "entered-in-error",
    Unknown = "unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationRequestSmdAbnormalFlagEnum {
    N = "N",
    A = "A",
    NotApplicable = "not_applicable",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultObservationRequestSmdPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
}

/**
 *
 * @export
 * @interface LabResultRequest
 */
export interface LabResultRequest {
    /**
     *
     * @type {string}
     * @memberof LabResultRequest
     */
    labOrderId: string;
    /**
     *
     * @type {string}
     * @memberof LabResultRequest
     */
    intakeGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof LabResultRequest
     */
    status?: LabResultRequestStatusEnum;
    /**
     * External Identifier for the Lab result. Lab results cannot be updated. Use this field to identify the same Lab results across different versions.
     * @type {string}
     * @memberof LabResultRequest
     */
    externalId: string | null;
    /**
     * Version number of the LabResult. Use this field to store multiple versions of the same LabResult
     * @type {string}
     * @memberof LabResultRequest
     */
    version: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof LabResultRequest
     */
    resultData: { [key: string]: any };
    /**
     * HL7 code for the interpretation, <a href=\"https://terminology.hl7.org/5.1.0/CodeSystem-v3-ObservationInterpretation.html\">HL7 LINK</a> has the list of codes
     * @type {string}
     * @memberof LabResultRequest
     */
    abnormalFlag?: string | null;
    /**
     * Lab Result Priority
     * @type {string}
     * @memberof LabResultRequest
     */
    priority?: LabResultRequestPriorityEnum;
    /**
     *
     * @type {string}
     * @memberof LabResultRequest
     */
    accessionNumber?: string | null;
    /**
     *
     * @type {Array<LabResultObservationRequest>}
     * @memberof LabResultRequest
     */
    observations?: Array<LabResultObservationRequest> | null;
    /**
     *
     * @type {Array<PlaceOfServiceRequest>}
     * @memberof LabResultRequest
     */
    placesOfService?: Array<PlaceOfServiceRequest> | null;
    /**
     *
     * @type {string}
     * @memberof LabResultRequest
     */
    conclusion?: string | null;
    /**
     * Date and time when the results were released
     * @type {string}
     * @memberof LabResultRequest
     */
    releasedAt?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum LabResultRequestStatusEnum {
    Registered = "registered",
    Partial = "partial",
    Preliminary = "preliminary",
    Final = "final",
    Corrected = "corrected",
    Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum LabResultRequestPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface Message
 */
export interface Message {
    /**
     *
     * @type {string}
     * @memberof Message
     */
    createdByName: string;
    /**
     *
     * @type {boolean}
     * @memberof Message
     */
    isPleasantry: boolean;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    recipient: MessageRecipientEnum;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    clinicianGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof Message
     */
    sentAt: string;
    /**
     *
     * @type {number}
     * @memberof Message
     */
    episode: number;
    /**
     *
     * @type {number}
     * @memberof Message
     */
    createdBy: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageRecipientEnum {
    Patient = "to_patient",
    Clinician = "to_clinician",
}

/**
 *
 * @export
 * @interface MessageAttachment
 */
export interface MessageAttachment {
    /**
     *
     * @type {string}
     * @memberof MessageAttachment
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof MessageAttachment
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof MessageAttachment
     */
    file: string;
    /**
     *
     * @type {string}
     * @memberof MessageAttachment
     */
    contentType?: MessageAttachmentContentTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageAttachmentContentTypeEnum {
    ApplicationPdf = "application/pdf",
    ImagePng = "image/png",
    ImageJpeg = "image/jpeg",
    ImageGif = "image/gif",
    ImageSvgxml = "image/svg+xml",
    ImageWebp = "image/webp",
}

/**
 *
 * @export
 * @interface MessageCreateRequest
 */
export interface MessageCreateRequest {
    /**
     *
     * @type {string}
     * @memberof MessageCreateRequest
     */
    episodeGuid: string;
    /**
     *
     * @type {string}
     * @memberof MessageCreateRequest
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof MessageCreateRequest
     */
    clinicianGuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof MessageCreateRequest
     */
    pleasantry?: number | null;
}
/**
 *
 * @export
 * @interface MessageWithAttachment
 */
export interface MessageWithAttachment {
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    createdByName: string;
    /**
     *
     * @type {boolean}
     * @memberof MessageWithAttachment
     */
    isPleasantry: boolean;
    /**
     *
     * @type {Array<MessageAttachment>}
     * @memberof MessageWithAttachment
     */
    attachments: Array<MessageAttachment>;
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    recipient: MessageWithAttachmentRecipientEnum;
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    clinicianGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof MessageWithAttachment
     */
    sentAt: string;
    /**
     *
     * @type {number}
     * @memberof MessageWithAttachment
     */
    episode: number;
    /**
     *
     * @type {number}
     * @memberof MessageWithAttachment
     */
    createdBy: number;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageWithAttachmentRecipientEnum {
    Patient = "to_patient",
    Clinician = "to_clinician",
}

/**
 *
 * @export
 * @interface Observation
 */
export interface Observation {
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    value: string;
    /**
     *
     * @type {number}
     * @memberof Observation
     */
    createdBy?: number;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    createdByName: string;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    encounterGuid: string;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    name: ObservationNameEnum;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    unit: ObservationUnitEnum;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof Observation
     */
    measuredAt?: string | null;
    /**
     *
     * @type {number}
     * @memberof Observation
     */
    intake: number;
    /**
     *
     * @type {number}
     * @memberof Observation
     */
    encounter: number;
    /**
     *
     * @type {number}
     * @memberof Observation
     */
    patient: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ObservationNameEnum {
    BloodGlucose = "blood_glucose",
    BloodPressure = "blood_pressure",
    HeartRate = "heart_rate",
    Height = "height",
    PregnancyStatus = "pregnancy_status",
    OxygenSaturation = "oxygen_saturation",
    Temperature = "temperature",
    SelfHarmRisk = "self_harm_risk",
    SuicideRisk = "suicide_risk",
    WaistCircumference = "waist_circumference",
    Weight = "weight",
}
/**
 * @export
 * @enum {string}
 */
export enum ObservationUnitEnum {
    Boolean = "boolean",
    Bpm = "bpm",
    C = "C",
    Cm = "cm",
    F = "F",
    In = "in",
    Kg = "kg",
    Percent = "%",
    Lb = "lb",
    MgDL = "mg/dL",
    MmHg = "mmHg",
    MmolL = "mmol/L",
}

/**
 *
 * @export
 * @interface ObservationRequest
 */
export interface ObservationRequest {
    /**
     *
     * @type {string}
     * @memberof ObservationRequest
     */
    value: string;
    /**
     *
     * @type {number}
     * @memberof ObservationRequest
     */
    createdBy?: number;
    /**
     *
     * @type {string}
     * @memberof ObservationRequest
     */
    name: ObservationRequestNameEnum;
    /**
     *
     * @type {string}
     * @memberof ObservationRequest
     */
    unit: ObservationRequestUnitEnum;
    /**
     *
     * @type {string}
     * @memberof ObservationRequest
     */
    measuredAt?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ObservationRequestNameEnum {
    BloodGlucose = "blood_glucose",
    BloodPressure = "blood_pressure",
    HeartRate = "heart_rate",
    Height = "height",
    PregnancyStatus = "pregnancy_status",
    OxygenSaturation = "oxygen_saturation",
    Temperature = "temperature",
    SelfHarmRisk = "self_harm_risk",
    SuicideRisk = "suicide_risk",
    WaistCircumference = "waist_circumference",
    Weight = "weight",
}
/**
 * @export
 * @enum {string}
 */
export enum ObservationRequestUnitEnum {
    Boolean = "boolean",
    Bpm = "bpm",
    C = "C",
    Cm = "cm",
    F = "F",
    In = "in",
    Kg = "kg",
    Percent = "%",
    Lb = "lb",
    MgDL = "mg/dL",
    MmHg = "mmHg",
    MmolL = "mmol/L",
}

/**
 *
 * @export
 * @interface PatchedEpisodeRequest
 */
export interface PatchedEpisodeRequest {
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatchedEpisodeRequest
     */
    diagnosis?: { [key: string]: any } | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    treatmentPlan?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    notes?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {Array<EpisodePrescriptions>}
     * @memberof PatchedEpisodeRequest
     */
    prescriptions?: Array<EpisodePrescriptions>;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatchedEpisodeRequest
     */
    manualPrescriptions?: { [key: string]: any };
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatchedEpisodeRequest
     */
    requestedPharmacy?: { [key: string]: any };
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyNpi?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyNcpdpid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyName?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyAddress1?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyAddress2?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyAddress3?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyCity?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyState?: PatchedEpisodeRequestPharmacyStateEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyZipcode?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyPhone?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyFax?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedEpisodeRequest
     */
    pharmacyDosespotId?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedEpisodeRequestPharmacyStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface PatchedLabOrderRequest
 */
export interface PatchedLabOrderRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    episodeGuid?: string;
    /**
     * Laboratory ID
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    laboratory?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    paymentResponsibility?: PatchedLabOrderRequestPaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    submissionMethod?: PatchedLabOrderRequestSubmissionMethodEnum;
    /**
     * Use this ID within 1 hour to fetch the order details. After 1 hour, retrying the order will generate a new request result ID and create a new lab order.
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    requestResultId?: string | null;
    /**
     * External Laboratory ID for the order. This is the ID can be used to retrieve the order
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    labOrderId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    diagnosticResultId?: string | null;
    /**
     * For partners that have multiple lab accounts, this field is used to specify which account to use.
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    labAccountId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    orderingClinician?: string | null;
    /**
     *
     * @type {Array<LabRequisitionShortRequest>}
     * @memberof PatchedLabOrderRequest
     */
    labRequisitions?: Array<LabRequisitionShortRequest>;
    /**
     * The reference ID which the lab collection site uses to identify the order
     * @type {string}
     * @memberof PatchedLabOrderRequest
     */
    labReferenceId?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatchedLabOrderRequest
     */
    labApprovedCriteria?: { [key: string]: any } | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedLabOrderRequestPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedLabOrderRequestSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface PatchedLabRequisitionResponseRequest
 */
export interface PatchedLabRequisitionResponseRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    laboratory?: string;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    labOrderCode?: string;
    /**
     * Example: Complete Blood Count (CBC) With Differential
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    labOrderName?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    paymentResponsibility?: PatchedLabRequisitionResponseRequestPaymentResponsibilityEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    submissionMethod?: PatchedLabRequisitionResponseRequestSubmissionMethodEnum;
    /**
     * Deprecated - Use LabOrder.labAccountId. For partners that have multiple lab accounts,  this field is used to specify which account to use.
     * @type {string}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    labAccountId?: string | null;
    /**
     *
     * @type {Array<LabOrderRequest>}
     * @memberof PatchedLabRequisitionResponseRequest
     */
    orders?: Array<LabOrderRequest>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedLabRequisitionResponseRequestPaymentResponsibilityEnum {
    Self = "self",
    Guarantor = "guarantor",
    Patient = "patient",
    ThirdParty = "thirdParty",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedLabRequisitionResponseRequestSubmissionMethodEnum {
    AbbottBridge = "ABBOTT_BRIDGE",
    HealthGorilla = "HEALTH_GORILLA",
    SelfSubmission = "SELF_SUBMISSION",
}

/**
 *
 * @export
 * @interface PatchedLabResultObservationRequest
 */
export interface PatchedLabResultObservationRequest {
    /**
     *
     * @type {Array<LabInterpretationRequest>}
     * @memberof PatchedLabResultObservationRequest
     */
    interpretations?: Array<LabInterpretationRequest>;
    /**
     *
     * @type {Array<LabReferenceRangeRequest>}
     * @memberof PatchedLabResultObservationRequest
     */
    referenceRanges?: Array<LabReferenceRangeRequest>;
    /**
     * Example: 005009 is the LabCorp order code for the CBC panel
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    labOrderCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    labOrderName?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    status?: PatchedLabResultObservationRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    code?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    codeText?: string | null;
    /**
     * Clinically relevant time/time-period for observation
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    effectiveAt?: string | null;
    /**
     * Date and Time this version was made available
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    issuedAt?: string | null;
    /**
     * Date and time the specimen was collected
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    collectedAt?: string | null;
    /**
     * The time when specimen is received by the testing laboratory
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    receivedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    value?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    unit?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    system?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    placeOfServiceCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    reportComments?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    smdAbnormalFlag?: PatchedLabResultObservationRequestSmdAbnormalFlagEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultObservationRequest
     */
    smdPriority?: PatchedLabResultObservationRequestSmdPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedLabResultObservationRequest
     */
    labRequisition?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedLabResultObservationRequestStatusEnum {
    Registered = "registered",
    Preliminary = "preliminary",
    Final = "final",
    Amended = "amended",
    Cancelled = "cancelled",
    Corrected = "corrected",
    EnteredInError = "entered-in-error",
    Unknown = "unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedLabResultObservationRequestSmdAbnormalFlagEnum {
    N = "N",
    A = "A",
    NotApplicable = "not_applicable",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedLabResultObservationRequestSmdPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
}

/**
 *
 * @export
 * @interface PatchedLabResultRequest
 */
export interface PatchedLabResultRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    labOrderId?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    intakeGuid?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    status?: PatchedLabResultRequestStatusEnum;
    /**
     * External Identifier for the Lab result. Lab results cannot be updated. Use this field to identify the same Lab results across different versions.
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    externalId?: string | null;
    /**
     * Version number of the LabResult. Use this field to store multiple versions of the same LabResult
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    version?: string | null;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatchedLabResultRequest
     */
    resultData?: { [key: string]: any };
    /**
     * HL7 code for the interpretation, <a href=\"https://terminology.hl7.org/5.1.0/CodeSystem-v3-ObservationInterpretation.html\">HL7 LINK</a> has the list of codes
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    abnormalFlag?: string | null;
    /**
     * Lab Result Priority
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    priority?: PatchedLabResultRequestPriorityEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    accessionNumber?: string | null;
    /**
     *
     * @type {Array<LabResultObservationRequest>}
     * @memberof PatchedLabResultRequest
     */
    observations?: Array<LabResultObservationRequest> | null;
    /**
     *
     * @type {Array<PlaceOfServiceRequest>}
     * @memberof PatchedLabResultRequest
     */
    placesOfService?: Array<PlaceOfServiceRequest> | null;
    /**
     *
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    conclusion?: string | null;
    /**
     * Date and time when the results were released
     * @type {string}
     * @memberof PatchedLabResultRequest
     */
    releasedAt?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedLabResultRequestStatusEnum {
    Registered = "registered",
    Partial = "partial",
    Preliminary = "preliminary",
    Final = "final",
    Corrected = "corrected",
    Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedLabResultRequestPriorityEnum {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    Normal = "NORMAL",
    NotApplicable = "not_applicable",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface PatchedPatientUpdateRequest
 */
export interface PatchedPatientUpdateRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedPatientUpdateRequest
     */
    healthGorillaId?: string | null;
}
/**
 *
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    patientGuid: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    episodeGuids: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    guid: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    phone: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    address1: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    address2?: string | null;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    usState: PatientUsStateEnum;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    zipCode: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    birthDate: string;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    gender?: PatientGenderEnum;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    maritalStatus?: PatientMaritalStatusEnum;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    dosespotId?: string | null;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    dosespotSandboxId?: string | null;
    /**
     *
     * @type {string}
     * @memberof Patient
     */
    healthGorillaId?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientUsStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientGenderEnum {
    Female = "female",
    Male = "male",
    Other = "other",
    Unknown = "unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientMaritalStatusEnum {
    A = "A",
    D = "D",
    I = "I",
    L = "L",
    M = "M",
    C = "C",
    P = "P",
    T = "T",
    U = "U",
    S = "S",
    W = "W",
    Unk = "UNK",
}

/**
 *
 * @export
 * @interface PatientAllergyIntolerance
 */
export interface PatientAllergyIntolerance {
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntolerance
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    display: string;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    updatedAt: string;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    source?: PatientAllergyIntoleranceSourceEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    patientAllergyId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    reaction?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    reactionType?: PatientAllergyIntoleranceReactionTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    status?: PatientAllergyIntoleranceStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    onsetDate?: string | null;
    /**
     * Clinician Dosespot ID
     * @type {string}
     * @memberof PatientAllergyIntolerance
     */
    lastUpdatedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof PatientAllergyIntolerance
     */
    isInDosespot?: boolean;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntolerance
     */
    createdBy?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntolerance
     */
    patient: number;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntolerance
     */
    code?: number | null;
    /**
     * The users who reported the allergy or intolerance.
     * @type {Array<number>}
     * @memberof PatientAllergyIntolerance
     */
    reportedBy: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceSourceEnum {
    DoseSpot = "DoseSpot",
    Partner = "Partner",
    Empty = "",
    Null = "null",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceReactionTypeEnum {
    Allergy = "Allergy",
    AdverseReaction = "AdverseReaction",
    Empty = "",
    Null = "null",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceStatusEnum {
    Active = "Active",
    Inactive = "Inactive",
    Deleted = "Deleted",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface PatientAllergyIntoleranceRequest
 */
export interface PatientAllergyIntoleranceRequest {
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    source?: PatientAllergyIntoleranceRequestSourceEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    patientAllergyId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    reaction?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    reactionType?: PatientAllergyIntoleranceRequestReactionTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    status?: PatientAllergyIntoleranceRequestStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    onsetDate?: string | null;
    /**
     * Clinician Dosespot ID
     * @type {string}
     * @memberof PatientAllergyIntoleranceRequest
     */
    lastUpdatedBy?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof PatientAllergyIntoleranceRequest
     */
    isInDosespot?: boolean;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntoleranceRequest
     */
    createdBy?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntoleranceRequest
     */
    patient: number;
    /**
     *
     * @type {number}
     * @memberof PatientAllergyIntoleranceRequest
     */
    code?: number | null;
    /**
     * The users who reported the allergy or intolerance.
     * @type {Array<number>}
     * @memberof PatientAllergyIntoleranceRequest
     */
    reportedBy: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceRequestSourceEnum {
    DoseSpot = "DoseSpot",
    Partner = "Partner",
    Empty = "",
    Null = "null",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceRequestReactionTypeEnum {
    Allergy = "Allergy",
    AdverseReaction = "AdverseReaction",
    Empty = "",
    Null = "null",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientAllergyIntoleranceRequestStatusEnum {
    Active = "Active",
    Inactive = "Inactive",
    Deleted = "Deleted",
    Empty = "",
    Null = "null",
}

/**
 *
 * @export
 * @interface PatientMedications
 */
export interface PatientMedications {
    /**
     *
     * @type {string}
     * @memberof PatientMedications
     */
    source: PatientMedicationsSourceEnum;
    /**
     *
     * @type {string}
     * @memberof PatientMedications
     */
    kind: PatientMedicationsKindEnum;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatientMedications
     */
    medications?: { [key: string]: any };
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicationsSourceEnum {
    Dosespot = "dosespot",
    Manual = "manual",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientMedicationsKindEnum {
    SelfReported = "self_reported",
    Prescribed = "prescribed",
}

/**
 *
 * @export
 * @interface PatientMedicationsRequest
 */
export interface PatientMedicationsRequest {
    /**
     *
     * @type {string}
     * @memberof PatientMedicationsRequest
     */
    source: PatientMedicationsRequestSourceEnum;
    /**
     *
     * @type {string}
     * @memberof PatientMedicationsRequest
     */
    kind: PatientMedicationsRequestKindEnum;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof PatientMedicationsRequest
     */
    medications?: { [key: string]: any };
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicationsRequestSourceEnum {
    Dosespot = "dosespot",
    Manual = "manual",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientMedicationsRequestKindEnum {
    SelfReported = "self_reported",
    Prescribed = "prescribed",
}

/**
 *
 * @export
 * @interface PatientRequest
 */
export interface PatientRequest {
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    patientGuid: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    phone: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    address1: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    address2?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    usState: PatientRequestUsStateEnum;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    zipCode: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    birthDate: string;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    gender?: PatientRequestGenderEnum;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    maritalStatus?: PatientRequestMaritalStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    dosespotId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    dosespotSandboxId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatientRequest
     */
    healthGorillaId?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientRequestUsStateEnum {
    Al = "AL",
    Ak = "AK",
    As = "AS",
    Az = "AZ",
    Ar = "AR",
    Aa = "AA",
    Ae = "AE",
    Ap = "AP",
    Ca = "CA",
    Co = "CO",
    Ct = "CT",
    De = "DE",
    Dc = "DC",
    Fl = "FL",
    Ga = "GA",
    Gu = "GU",
    Hi = "HI",
    Id = "ID",
    Il = "IL",
    In = "IN",
    Ia = "IA",
    Ks = "KS",
    Ky = "KY",
    La = "LA",
    Me = "ME",
    Md = "MD",
    Ma = "MA",
    Mi = "MI",
    Mn = "MN",
    Ms = "MS",
    Mo = "MO",
    Mt = "MT",
    Ne = "NE",
    Nv = "NV",
    Nh = "NH",
    Nj = "NJ",
    Nm = "NM",
    Ny = "NY",
    Nc = "NC",
    Nd = "ND",
    Mp = "MP",
    Oh = "OH",
    Ok = "OK",
    Or = "OR",
    Pa = "PA",
    Pr = "PR",
    Ri = "RI",
    Sc = "SC",
    Sd = "SD",
    Tn = "TN",
    Tx = "TX",
    Ut = "UT",
    Vt = "VT",
    Vi = "VI",
    Va = "VA",
    Wa = "WA",
    Wv = "WV",
    Wi = "WI",
    Wy = "WY",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientRequestGenderEnum {
    Female = "female",
    Male = "male",
    Other = "other",
    Unknown = "unknown",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientRequestMaritalStatusEnum {
    A = "A",
    D = "D",
    I = "I",
    L = "L",
    M = "M",
    C = "C",
    P = "P",
    T = "T",
    U = "U",
    S = "S",
    W = "W",
    Unk = "UNK",
}

/**
 *
 * @export
 * @interface PatientUpdate
 */
export interface PatientUpdate {
    /**
     *
     * @type {string}
     * @memberof PatientUpdate
     */
    healthGorillaId?: string | null;
}
/**
 *
 * @export
 * @interface PlaceOfService
 */
export interface PlaceOfService {
    /**
     *
     * @type {number}
     * @memberof PlaceOfService
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    address1: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    address2?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    zip: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    director: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    license?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfService
     */
    code?: string | null;
}
/**
 *
 * @export
 * @interface PlaceOfServiceRequest
 */
export interface PlaceOfServiceRequest {
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    address1: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    address2?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    zip: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    director: string;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    license?: string | null;
    /**
     *
     * @type {string}
     * @memberof PlaceOfServiceRequest
     */
    code?: string | null;
}
/**
 *
 * @export
 * @interface VerificationClaim
 */
export interface VerificationClaim {
    /**
     *
     * @type {string}
     * @memberof VerificationClaim
     */
    claimedAt: string;
    /**
     *
     * @type {string}
     * @memberof VerificationClaim
     */
    claimedByName?: string | null;
    /**
     *
     * @type {string}
     * @memberof VerificationClaim
     */
    claimType: VerificationClaimClaimTypeEnum;
    /**
     *
     * @type {string}
     * @memberof VerificationClaim
     */
    consultGuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerificationClaim
     */
    episode: number;
    /**
     *
     * @type {number}
     * @memberof VerificationClaim
     */
    labOrder?: number | null;
    /**
     *
     * @type {number}
     * @memberof VerificationClaim
     */
    claimedBy: number;
}

/**
 * @export
 * @enum {string}
 */
export enum VerificationClaimClaimTypeEnum {
    AllergyVerification = "allergy_verification",
    ClinicalNoteVerification = "clinical_note_verification",
    External = "external",
    Internal = "internal",
    LabOrderRatified = "lab_order_ratified",
    LabOrderRejected = "lab_order_rejected",
    ParentalConsent = "parental_consent",
}

/**
 *
 * @export
 * @interface VerificationClaimCreateRequest
 */
export interface VerificationClaimCreateRequest {
    /**
     *
     * @type {string}
     * @memberof VerificationClaimCreateRequest
     */
    episodeGuid: string;
    /**
     *
     * @type {string}
     * @memberof VerificationClaimCreateRequest
     */
    claimType: VerificationClaimCreateRequestClaimTypeEnum;
    /**
     *
     * @type {string}
     * @memberof VerificationClaimCreateRequest
     */
    consultGuid?: string | null;
    /**
     *
     * @type {number}
     * @memberof VerificationClaimCreateRequest
     */
    labOrder?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum VerificationClaimCreateRequestClaimTypeEnum {
    AllergyVerification = "allergy_verification",
    ClinicalNoteVerification = "clinical_note_verification",
    External = "external",
    Internal = "internal",
    LabOrderRatified = "lab_order_ratified",
    LabOrderRejected = "lab_order_rejected",
    ParentalConsent = "parental_consent",
}

/**
 * EncounterApi - axios parameter creator
 * @export
 */
export const EncounterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @summary Cancel Encounter
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterCancelEncounterCreate: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("encounterCancelEncounterCreate", "guid", guid);
            const localVarPath = `/clinician-api/encounter/{guid}/cancel_encounter`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {EncounterRequest} encounterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterCreate: async (encounterRequest: EncounterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'encounterRequest' is not null or undefined
            assertParamExists("encounterCreate", "encounterRequest", encounterRequest);
            const localVarPath = `/clinician-api/encounter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(encounterRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterDestroy: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("encounterDestroy", "guid", guid);
            const localVarPath = `/clinician-api/encounter/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterList: async (episodeGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/encounter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (episodeGuid !== undefined) {
                localVarQueryParameter["episode__guid"] = episodeGuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("encounterRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/encounter/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EncounterApi - functional programming interface
 * @export
 */
export const EncounterApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EncounterApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @summary Cancel Encounter
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encounterCancelEncounterCreate(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encounterCancelEncounterCreate(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {EncounterRequest} encounterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encounterCreate(
            encounterRequest: EncounterRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Encounter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encounterCreate(encounterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encounterDestroy(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encounterDestroy(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encounterList(
            episodeGuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Encounter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encounterList(episodeGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encounterRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Encounter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encounterRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EncounterApi - factory interface
 * @export
 */
export const EncounterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EncounterApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @summary Cancel Encounter
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterCancelEncounterCreate(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.encounterCancelEncounterCreate(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {EncounterRequest} encounterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterCreate(encounterRequest: EncounterRequest, options?: any): AxiosPromise<Encounter> {
            return localVarFp.encounterCreate(encounterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterDestroy(guid: string, options?: any): AxiosPromise<void> {
            return localVarFp.encounterDestroy(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterList(episodeGuid?: string, options?: any): AxiosPromise<Array<Encounter>> {
            return localVarFp.encounterList(episodeGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encounterRetrieve(guid: string, options?: any): AxiosPromise<Encounter> {
            return localVarFp.encounterRetrieve(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EncounterApi - object-oriented interface
 * @export
 * @class EncounterApi
 * @extends {BaseAPI}
 */
export class EncounterApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @summary Cancel Encounter
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncounterApi
     */
    public encounterCancelEncounterCreate(guid: string, options?: AxiosRequestConfig) {
        return EncounterApiFp(this.configuration)
            .encounterCancelEncounterCreate(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {EncounterRequest} encounterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncounterApi
     */
    public encounterCreate(encounterRequest: EncounterRequest, options?: AxiosRequestConfig) {
        return EncounterApiFp(this.configuration)
            .encounterCreate(encounterRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncounterApi
     */
    public encounterDestroy(guid: string, options?: AxiosRequestConfig) {
        return EncounterApiFp(this.configuration)
            .encounterDestroy(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [episodeGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncounterApi
     */
    public encounterList(episodeGuid?: string, options?: AxiosRequestConfig) {
        return EncounterApiFp(this.configuration)
            .encounterList(episodeGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncounterApi
     */
    public encounterRetrieve(guid: string, options?: AxiosRequestConfig) {
        return EncounterApiFp(this.configuration)
            .encounterRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * EpisodeApi - axios parameter creator
 * @export
 */
export const EpisodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeCreateClinicalNoteCreate: async (guid: string, episodeRequest: EpisodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeCreateClinicalNoteCreate", "guid", guid);
            // verify required parameter 'episodeRequest' is not null or undefined
            assertParamExists("episodeCreateClinicalNoteCreate", "episodeRequest", episodeRequest);
            const localVarPath = `/clinician-api/episode/{guid}/create_clinical_note`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDenyLabNotesUpdate: async (
            guid: string,
            labDispositionUpdateRequest: LabDispositionUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeDenyLabNotesUpdate", "guid", guid);
            // verify required parameter 'labDispositionUpdateRequest' is not null or undefined
            assertParamExists("episodeDenyLabNotesUpdate", "labDispositionUpdateRequest", labDispositionUpdateRequest);
            const localVarPath = `/clinician-api/episode/{guid}/deny_lab_notes`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labDispositionUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRefillsErrorsCountsUpdate: async (
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'episodeDosespotUpdateRequest' is not null or undefined
            assertParamExists("episodeDosespotRefillsErrorsCountsUpdate", "episodeDosespotUpdateRequest", episodeDosespotUpdateRequest);
            const localVarPath = `/clinician-api/episode/dosespot_refills_errors_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeDosespotUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRefillsErrorsUrlUpdate: async (
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'episodeDosespotUpdateRequest' is not null or undefined
            assertParamExists("episodeDosespotRefillsErrorsUrlUpdate", "episodeDosespotUpdateRequest", episodeDosespotUpdateRequest);
            const localVarPath = `/clinician-api/episode/dosespot_refills_errors_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeDosespotUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRxCreate: async (
            guid: string,
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeDosespotRxCreate", "guid", guid);
            // verify required parameter 'episodeDosespotUpdateRequest' is not null or undefined
            assertParamExists("episodeDosespotRxCreate", "episodeDosespotUpdateRequest", episodeDosespotUpdateRequest);
            const localVarPath = `/clinician-api/episode/{guid}/dosespot_rx`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeDosespotUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespoturlCreate: async (
            guid: string,
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeDosespoturlCreate", "guid", guid);
            // verify required parameter 'episodeDosespotUpdateRequest' is not null or undefined
            assertParamExists("episodeDosespoturlCreate", "episodeDosespotUpdateRequest", episodeDosespotUpdateRequest);
            const localVarPath = `/clinician-api/episode/{guid}/dosespoturl`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeDosespotUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeIntakesHighlightedRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeIntakesHighlightedRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}/intakes/highlighted`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeLabApproveUpdate: async (
            guid: string,
            labDispositionUpdateRequest: LabDispositionUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeLabApproveUpdate", "guid", guid);
            // verify required parameter 'labDispositionUpdateRequest' is not null or undefined
            assertParamExists("episodeLabApproveUpdate", "labDispositionUpdateRequest", labDispositionUpdateRequest);
            const localVarPath = `/clinician-api/episode/{guid}/lab_approve`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labDispositionUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} labRequisitionGuid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeLabRequisitionResultCreate: async (
            guid: string,
            labRequisitionGuid: string,
            labResultRequest: LabResultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeLabRequisitionResultCreate", "guid", guid);
            // verify required parameter 'labRequisitionGuid' is not null or undefined
            assertParamExists("episodeLabRequisitionResultCreate", "labRequisitionGuid", labRequisitionGuid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("episodeLabRequisitionResultCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/episode/{guid}/lab/requisition/{labRequisitionGuid}/result`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"labRequisitionGuid"}}`, encodeURIComponent(String(labRequisitionGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePharmacyRequest} [episodeUpdatePharmacyRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeManualPharmacyUpdate: async (
            guid: string,
            episodeUpdatePharmacyRequest?: EpisodeUpdatePharmacyRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeManualPharmacyUpdate", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}/manual_pharmacy`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeUpdatePharmacyRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePrescriptionRequest} [episodeUpdatePrescriptionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeManualPrescriptionUpdate: async (
            guid: string,
            episodeUpdatePrescriptionRequest?: EpisodeUpdatePrescriptionRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeManualPrescriptionUpdate", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}/manual_prescription`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeUpdatePrescriptionRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {PatchedEpisodeRequest} [patchedEpisodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodePartialUpdate: async (guid: string, patchedEpisodeRequest?: PatchedEpisodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodePartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedEpisodeRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} requisitionGuid
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRequisitionRetrieve: async (guid: string, requisitionGuid: string, _var: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeRequisitionRetrieve", "guid", guid);
            // verify required parameter 'requisitionGuid' is not null or undefined
            assertParamExists("episodeRequisitionRetrieve", "requisitionGuid", requisitionGuid);
            // verify required parameter '_var' is not null or undefined
            assertParamExists("episodeRequisitionRetrieve", "_var", _var);
            const localVarPath = `/clinician-api/episode/{guid}/requisition/{var}{requisitionGuid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"requisitionGuid"}}`, encodeURIComponent(String(requisitionGuid)))
                .replace(`{${"var"}}`, encodeURIComponent(String(_var)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrieveClinicalNoteRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeRetrieveClinicalNoteRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}/retrieve_clinical_note`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrievePastEpisodesRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeRetrievePastEpisodesRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/episode/{guid}/retrieve_past_episodes`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {boolean} [completeEncounter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeUpdate: async (
            guid: string,
            episodeRequest: EpisodeRequest,
            completeEncounter?: boolean,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeUpdate", "guid", guid);
            // verify required parameter 'episodeRequest' is not null or undefined
            assertParamExists("episodeUpdate", "episodeRequest", episodeRequest);
            const localVarPath = `/clinician-api/episode/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (completeEncounter !== undefined) {
                localVarQueryParameter["complete_encounter"] = completeEncounter;
            }

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(episodeRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {ClinicalNoteRequest} clinicalNoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeVerifyClinicalNoteCreate: async (
            guid: string,
            clinicalNoteRequest: ClinicalNoteRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("episodeVerifyClinicalNoteCreate", "guid", guid);
            // verify required parameter 'clinicalNoteRequest' is not null or undefined
            assertParamExists("episodeVerifyClinicalNoteCreate", "clinicalNoteRequest", clinicalNoteRequest);
            const localVarPath = `/clinician-api/episode/{guid}/verify_clinical_note`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(clinicalNoteRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EpisodeApi - functional programming interface
 * @export
 */
export const EpisodeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EpisodeApiAxiosParamCreator(configuration);
    return {
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeCreateClinicalNoteCreate(
            guid: string,
            episodeRequest: EpisodeRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeCreateClinicalNoteCreate(guid, episodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeDenyLabNotesUpdate(
            guid: string,
            labDispositionUpdateRequest: LabDispositionUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeDenyLabNotesUpdate(guid, labDispositionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeDosespotRefillsErrorsCountsUpdate(
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeDosespotRefillsErrorsCountsUpdate(episodeDosespotUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeDosespotRefillsErrorsUrlUpdate(
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeDosespotRefillsErrorsUrlUpdate(episodeDosespotUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeDosespotRxCreate(
            guid: string,
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeDosespotRxCreate(guid, episodeDosespotUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeDosespoturlCreate(
            guid: string,
            episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeDosespoturlCreate(guid, episodeDosespotUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeIntakesHighlightedRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeIntakesHighlightedRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeLabApproveUpdate(
            guid: string,
            labDispositionUpdateRequest: LabDispositionUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeLabApproveUpdate(guid, labDispositionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} labRequisitionGuid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeLabRequisitionResultCreate(
            guid: string,
            labRequisitionGuid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeLabRequisitionResultCreate(guid, labRequisitionGuid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePharmacyRequest} [episodeUpdatePharmacyRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeManualPharmacyUpdate(
            guid: string,
            episodeUpdatePharmacyRequest?: EpisodeUpdatePharmacyRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeManualPharmacyUpdate(guid, episodeUpdatePharmacyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePrescriptionRequest} [episodeUpdatePrescriptionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeManualPrescriptionUpdate(
            guid: string,
            episodeUpdatePrescriptionRequest?: EpisodeUpdatePrescriptionRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeManualPrescriptionUpdate(guid, episodeUpdatePrescriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {PatchedEpisodeRequest} [patchedEpisodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodePartialUpdate(
            guid: string,
            patchedEpisodeRequest?: PatchedEpisodeRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodePartialUpdate(guid, patchedEpisodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} requisitionGuid
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeRequisitionRetrieve(
            guid: string,
            requisitionGuid: string,
            _var: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeRequisitionRetrieve(guid, requisitionGuid, _var, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeRetrieveClinicalNoteRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeRetrieveClinicalNoteRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeRetrievePastEpisodesRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeGuidOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeRetrievePastEpisodesRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {boolean} [completeEncounter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeUpdate(
            guid: string,
            episodeRequest: EpisodeRequest,
            completeEncounter?: boolean,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeUpdate(guid, episodeRequest, completeEncounter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {ClinicalNoteRequest} clinicalNoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async episodeVerifyClinicalNoteCreate(
            guid: string,
            clinicalNoteRequest: ClinicalNoteRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Episode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeVerifyClinicalNoteCreate(guid, clinicalNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * EpisodeApi - factory interface
 * @export
 */
export const EpisodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EpisodeApiFp(configuration);
    return {
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeCreateClinicalNoteCreate(guid: string, episodeRequest: EpisodeRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeCreateClinicalNoteCreate(guid, episodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDenyLabNotesUpdate(guid: string, labDispositionUpdateRequest: LabDispositionUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeDenyLabNotesUpdate(guid, labDispositionUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRefillsErrorsCountsUpdate(episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeDosespotRefillsErrorsCountsUpdate(episodeDosespotUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRefillsErrorsUrlUpdate(episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeDosespotRefillsErrorsUrlUpdate(episodeDosespotUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespotRxCreate(guid: string, episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeDosespotRxCreate(guid, episodeDosespotUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeDosespoturlCreate(guid: string, episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeDosespoturlCreate(guid, episodeDosespotUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeIntakesHighlightedRetrieve(guid: string, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeIntakesHighlightedRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeLabApproveUpdate(guid: string, labDispositionUpdateRequest: LabDispositionUpdateRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeLabApproveUpdate(guid, labDispositionUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} labRequisitionGuid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeLabRequisitionResultCreate(guid: string, labRequisitionGuid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp
                .episodeLabRequisitionResultCreate(guid, labRequisitionGuid, labResultRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePharmacyRequest} [episodeUpdatePharmacyRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeManualPharmacyUpdate(guid: string, episodeUpdatePharmacyRequest?: EpisodeUpdatePharmacyRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeManualPharmacyUpdate(guid, episodeUpdatePharmacyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeUpdatePrescriptionRequest} [episodeUpdatePrescriptionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeManualPrescriptionUpdate(
            guid: string,
            episodeUpdatePrescriptionRequest?: EpisodeUpdatePrescriptionRequest,
            options?: any
        ): AxiosPromise<Episode> {
            return localVarFp.episodeManualPrescriptionUpdate(guid, episodeUpdatePrescriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {PatchedEpisodeRequest} [patchedEpisodeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodePartialUpdate(guid: string, patchedEpisodeRequest?: PatchedEpisodeRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodePartialUpdate(guid, patchedEpisodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {string} requisitionGuid
         * @param {string} _var
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRequisitionRetrieve(guid: string, requisitionGuid: string, _var: string, options?: any): AxiosPromise<{ [key: string]: any }> {
            return localVarFp.episodeRequisitionRetrieve(guid, requisitionGuid, _var, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrieve(guid: string, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrieveClinicalNoteRetrieve(guid: string, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeRetrieveClinicalNoteRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeRetrievePastEpisodesRetrieve(guid: string, options?: any): AxiosPromise<EpisodeGuidOnly> {
            return localVarFp.episodeRetrievePastEpisodesRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {EpisodeRequest} episodeRequest
         * @param {boolean} [completeEncounter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeUpdate(guid: string, episodeRequest: EpisodeRequest, completeEncounter?: boolean, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeUpdate(guid, episodeRequest, completeEncounter, options).then((request) => request(axios, basePath));
        },
        /**
         * Episode of Care
         * @param {string} guid
         * @param {ClinicalNoteRequest} clinicalNoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        episodeVerifyClinicalNoteCreate(guid: string, clinicalNoteRequest: ClinicalNoteRequest, options?: any): AxiosPromise<Episode> {
            return localVarFp.episodeVerifyClinicalNoteCreate(guid, clinicalNoteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EpisodeApi - object-oriented interface
 * @export
 * @class EpisodeApi
 * @extends {BaseAPI}
 */
export class EpisodeApi extends BaseAPI {
    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeRequest} episodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeCreateClinicalNoteCreate(guid: string, episodeRequest: EpisodeRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeCreateClinicalNoteCreate(guid, episodeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeDenyLabNotesUpdate(guid: string, labDispositionUpdateRequest: LabDispositionUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeDenyLabNotesUpdate(guid, labDispositionUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeDosespotRefillsErrorsCountsUpdate(episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeDosespotRefillsErrorsCountsUpdate(episodeDosespotUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeDosespotRefillsErrorsUrlUpdate(episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeDosespotRefillsErrorsUrlUpdate(episodeDosespotUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeDosespotRxCreate(guid: string, episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeDosespotRxCreate(guid, episodeDosespotUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeDosespotUpdateRequest} episodeDosespotUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeDosespoturlCreate(guid: string, episodeDosespotUpdateRequest: EpisodeDosespotUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeDosespoturlCreate(guid, episodeDosespotUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeIntakesHighlightedRetrieve(guid: string, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeIntakesHighlightedRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {LabDispositionUpdateRequest} labDispositionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeLabApproveUpdate(guid: string, labDispositionUpdateRequest: LabDispositionUpdateRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeLabApproveUpdate(guid, labDispositionUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {string} labRequisitionGuid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeLabRequisitionResultCreate(guid: string, labRequisitionGuid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeLabRequisitionResultCreate(guid, labRequisitionGuid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeUpdatePharmacyRequest} [episodeUpdatePharmacyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeManualPharmacyUpdate(guid: string, episodeUpdatePharmacyRequest?: EpisodeUpdatePharmacyRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeManualPharmacyUpdate(guid, episodeUpdatePharmacyRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeUpdatePrescriptionRequest} [episodeUpdatePrescriptionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeManualPrescriptionUpdate(guid: string, episodeUpdatePrescriptionRequest?: EpisodeUpdatePrescriptionRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeManualPrescriptionUpdate(guid, episodeUpdatePrescriptionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {PatchedEpisodeRequest} [patchedEpisodeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodePartialUpdate(guid: string, patchedEpisodeRequest?: PatchedEpisodeRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodePartialUpdate(guid, patchedEpisodeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {string} requisitionGuid
     * @param {string} _var
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeRequisitionRetrieve(guid: string, requisitionGuid: string, _var: string, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeRequisitionRetrieve(guid, requisitionGuid, _var, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeRetrieve(guid: string, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeRetrieveClinicalNoteRetrieve(guid: string, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeRetrieveClinicalNoteRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeRetrievePastEpisodesRetrieve(guid: string, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeRetrievePastEpisodesRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {EpisodeRequest} episodeRequest
     * @param {boolean} [completeEncounter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeUpdate(guid: string, episodeRequest: EpisodeRequest, completeEncounter?: boolean, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeUpdate(guid, episodeRequest, completeEncounter, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Episode of Care
     * @param {string} guid
     * @param {ClinicalNoteRequest} clinicalNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodeApi
     */
    public episodeVerifyClinicalNoteCreate(guid: string, clinicalNoteRequest: ClinicalNoteRequest, options?: AxiosRequestConfig) {
        return EpisodeApiFp(this.configuration)
            .episodeVerifyClinicalNoteCreate(guid, clinicalNoteRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * FileAttachmentApi - axios parameter creator
 * @export
 */
export const FileAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} createdBy
         * @param {string} kind
         * @param {any} file
         * @param {string} [description]
         * @param {string} [externalId]
         * @param {string} [checksum]
         * @param {string} [groupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileAttachmentCreate: async (
            createdBy: number,
            kind: string,
            file: any,
            description?: string,
            externalId?: string,
            checksum?: string,
            groupId?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createdBy' is not null or undefined
            assertParamExists("fileAttachmentCreate", "createdBy", createdBy);
            // verify required parameter 'kind' is not null or undefined
            assertParamExists("fileAttachmentCreate", "kind", kind);
            // verify required parameter 'file' is not null or undefined
            assertParamExists("fileAttachmentCreate", "file", file);
            const localVarPath = `/clinician-api/file-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (createdBy !== undefined) {
                localVarFormParams.append("createdBy", createdBy as any);
            }

            if (kind !== undefined) {
                localVarFormParams.append("kind", kind as any);
            }

            if (description !== undefined) {
                localVarFormParams.append("description", description as any);
            }

            if (file !== undefined) {
                localVarFormParams.append("file", file as any);
            }

            if (externalId !== undefined) {
                localVarFormParams.append("externalId", externalId as any);
            }

            if (checksum !== undefined) {
                localVarFormParams.append("checksum", checksum as any);
            }

            if (groupId !== undefined) {
                localVarFormParams.append("groupId", groupId as any);
            }

            localVarHeaderParameter["Content-Type"] = "multipart/form-data";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileAttachmentList: async (externalId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/file-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (externalId !== undefined) {
                localVarQueryParameter["external_id"] = externalId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FileAttachmentApi - functional programming interface
 * @export
 */
export const FileAttachmentApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FileAttachmentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} createdBy
         * @param {string} kind
         * @param {any} file
         * @param {string} [description]
         * @param {string} [externalId]
         * @param {string} [checksum]
         * @param {string} [groupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileAttachmentCreate(
            createdBy: number,
            kind: string,
            file: any,
            description?: string,
            externalId?: string,
            checksum?: string,
            groupId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileAttachmentCreate(
                createdBy,
                kind,
                file,
                description,
                externalId,
                checksum,
                groupId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileAttachmentList(
            externalId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileAttachmentList(externalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FileAttachmentApi - factory interface
 * @export
 */
export const FileAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileAttachmentApiFp(configuration);
    return {
        /**
         *
         * @param {number} createdBy
         * @param {string} kind
         * @param {any} file
         * @param {string} [description]
         * @param {string} [externalId]
         * @param {string} [checksum]
         * @param {string} [groupId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileAttachmentCreate(
            createdBy: number,
            kind: string,
            file: any,
            description?: string,
            externalId?: string,
            checksum?: string,
            groupId?: string,
            options?: any
        ): AxiosPromise<FileAttachment> {
            return localVarFp
                .fileAttachmentCreate(createdBy, kind, file, description, externalId, checksum, groupId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileAttachmentList(externalId?: string, options?: any): AxiosPromise<Array<FileAttachment>> {
            return localVarFp.fileAttachmentList(externalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileAttachmentApi - object-oriented interface
 * @export
 * @class FileAttachmentApi
 * @extends {BaseAPI}
 */
export class FileAttachmentApi extends BaseAPI {
    /**
     *
     * @param {number} createdBy
     * @param {string} kind
     * @param {any} file
     * @param {string} [description]
     * @param {string} [externalId]
     * @param {string} [checksum]
     * @param {string} [groupId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentApi
     */
    public fileAttachmentCreate(
        createdBy: number,
        kind: string,
        file: any,
        description?: string,
        externalId?: string,
        checksum?: string,
        groupId?: string,
        options?: AxiosRequestConfig
    ) {
        return FileAttachmentApiFp(this.configuration)
            .fileAttachmentCreate(createdBy, kind, file, description, externalId, checksum, groupId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [externalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentApi
     */
    public fileAttachmentList(externalId?: string, options?: AxiosRequestConfig) {
        return FileAttachmentApiFp(this.configuration)
            .fileAttachmentList(externalId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * IntakeApi - axios parameter creator
 * @export
 */
export const IntakeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Intakes are amazings
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intakeRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("intakeRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/intake/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * IntakeApi - functional programming interface
 * @export
 */
export const IntakeApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = IntakeApiAxiosParamCreator(configuration);
    return {
        /**
         * Intakes are amazings
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intakeRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Intake>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.intakeRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * IntakeApi - factory interface
 * @export
 */
export const IntakeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntakeApiFp(configuration);
    return {
        /**
         * Intakes are amazings
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intakeRetrieve(guid: string, options?: any): AxiosPromise<Intake> {
            return localVarFp.intakeRetrieve(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntakeApi - object-oriented interface
 * @export
 * @class IntakeApi
 * @extends {BaseAPI}
 */
export class IntakeApi extends BaseAPI {
    /**
     * Intakes are amazings
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public intakeRetrieve(guid: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration)
            .intakeRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * IntakeFileApi - axios parameter creator
 * @export
 */
export const IntakeFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} intakeGuid
         * @param {any} file
         * @param {string} kind
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intakeFileCreate: async (intakeGuid: string, file: any, kind: string, externalId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'intakeGuid' is not null or undefined
            assertParamExists("intakeFileCreate", "intakeGuid", intakeGuid);
            // verify required parameter 'file' is not null or undefined
            assertParamExists("intakeFileCreate", "file", file);
            // verify required parameter 'kind' is not null or undefined
            assertParamExists("intakeFileCreate", "kind", kind);
            const localVarPath = `/clinician-api/intakeFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (intakeGuid !== undefined) {
                localVarFormParams.append("intakeGuid", intakeGuid as any);
            }

            if (externalId !== undefined) {
                localVarFormParams.append("externalId", externalId as any);
            }

            if (file !== undefined) {
                localVarFormParams.append("file", file as any);
            }

            if (kind !== undefined) {
                localVarFormParams.append("kind", kind as any);
            }

            localVarHeaderParameter["Content-Type"] = "multipart/form-data";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * IntakeFileApi - functional programming interface
 * @export
 */
export const IntakeFileApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = IntakeFileApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} intakeGuid
         * @param {any} file
         * @param {string} kind
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intakeFileCreate(
            intakeGuid: string,
            file: any,
            kind: string,
            externalId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeFileCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.intakeFileCreate(intakeGuid, file, kind, externalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * IntakeFileApi - factory interface
 * @export
 */
export const IntakeFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntakeFileApiFp(configuration);
    return {
        /**
         *
         * @param {string} intakeGuid
         * @param {any} file
         * @param {string} kind
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intakeFileCreate(intakeGuid: string, file: any, kind: string, externalId?: string, options?: any): AxiosPromise<IntakeFileCreate> {
            return localVarFp.intakeFileCreate(intakeGuid, file, kind, externalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntakeFileApi - object-oriented interface
 * @export
 * @class IntakeFileApi
 * @extends {BaseAPI}
 */
export class IntakeFileApi extends BaseAPI {
    /**
     *
     * @param {string} intakeGuid
     * @param {any} file
     * @param {string} kind
     * @param {string} [externalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeFileApi
     */
    public intakeFileCreate(intakeGuid: string, file: any, kind: string, externalId?: string, options?: AxiosRequestConfig) {
        return IntakeFileApiFp(this.configuration)
            .intakeFileCreate(intakeGuid, file, kind, externalId, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LabApi - axios parameter creator
 * @export
 */
export const LabApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultCreate2: async (labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultCreate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultFileAttachmentCreate2: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultFileAttachmentCreate2", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultFileAttachmentCreate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result/{guid}/file-attachment`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultInterpretationClinicalCreate2: async (
            guid: string,
            labResultRequest: LabResultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultInterpretationClinicalCreate2", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultInterpretationClinicalCreate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result/{guid}/interpretation/clinical`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultList2: async (externalId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/lab/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (externalId !== undefined) {
                localVarQueryParameter["external_id"] = externalId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultPartialUpdate2: async (
            guid: string,
            patchedLabResultRequest?: PatchedLabResultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultPartialUpdate2", "guid", guid);
            const localVarPath = `/clinician-api/lab/result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLabResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultReleaseCreate2: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultReleaseCreate2", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultReleaseCreate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result/{guid}/release`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultResolveOrphanedCreate2: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultResolveOrphanedCreate2", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultResolveOrphanedCreate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result/{guid}/resolve-orphaned`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultRetrieve2: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultRetrieve2", "guid", guid);
            const localVarPath = `/clinician-api/lab/result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultUpdate2: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultUpdate2", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultUpdate2", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab/result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LabApi - functional programming interface
 * @export
 */
export const LabApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LabApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultCreate2(
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultCreate2(labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultFileAttachmentCreate2(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultFileAttachmentCreate2(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultInterpretationClinicalCreate2(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultInterpretationClinicalCreate2(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultList2(
            externalId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultList2(externalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultPartialUpdate2(
            guid: string,
            patchedLabResultRequest?: PatchedLabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultPartialUpdate2(guid, patchedLabResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultReleaseCreate2(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultReleaseCreate2(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultResolveOrphanedCreate2(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultResolveOrphanedCreate2(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultRetrieve2(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultRetrieve2(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultUpdate2(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultUpdate2(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LabApi - factory interface
 * @export
 */
export const LabApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultCreate2(labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultCreate2(labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultFileAttachmentCreate2(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultFileAttachmentCreate2(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultInterpretationClinicalCreate2(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultInterpretationClinicalCreate2(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultList2(externalId?: string, options?: any): AxiosPromise<Array<LabResult>> {
            return localVarFp.labResultList2(externalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultPartialUpdate2(guid: string, patchedLabResultRequest?: PatchedLabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultPartialUpdate2(guid, patchedLabResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultReleaseCreate2(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultReleaseCreate2(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultResolveOrphanedCreate2(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultResolveOrphanedCreate2(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultRetrieve2(guid: string, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultRetrieve2(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultUpdate2(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultUpdate2(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabApi - object-oriented interface
 * @export
 * @class LabApi
 * @extends {BaseAPI}
 */
export class LabApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultCreate2(labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultCreate2(labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultFileAttachmentCreate2(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultFileAttachmentCreate2(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultInterpretationClinicalCreate2(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultInterpretationClinicalCreate2(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [externalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultList2(externalId?: string, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultList2(externalId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {PatchedLabResultRequest} [patchedLabResultRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultPartialUpdate2(guid: string, patchedLabResultRequest?: PatchedLabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultPartialUpdate2(guid, patchedLabResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultReleaseCreate2(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultReleaseCreate2(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultResolveOrphanedCreate2(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultResolveOrphanedCreate2(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultRetrieve2(guid: string, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultRetrieve2(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabApi
     */
    public labResultUpdate2(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabApiFp(this.configuration)
            .labResultUpdate2(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LabOrderApi - axios parameter creator
 * @export
 */
export const LabOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderCreate: async (labOrderRequest: LabOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labOrderRequest' is not null or undefined
            assertParamExists("labOrderCreate", "labOrderRequest", labOrderRequest);
            const localVarPath = `/clinician-api/lab-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderFileAttachmentCreate: async (guid: string, labOrderRequest: LabOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labOrderFileAttachmentCreate", "guid", guid);
            // verify required parameter 'labOrderRequest' is not null or undefined
            assertParamExists("labOrderFileAttachmentCreate", "labOrderRequest", labOrderRequest);
            const localVarPath = `/clinician-api/lab-order/{guid}/file-attachment`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [diagnosticResultId]
         * @param {string} [episodeGuid]
         * @param {string} [guid]
         * @param {string} [labOrderId]
         * @param {string} [labRequisitionsGuid]
         * @param {string} [requestResultId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderList: async (
            diagnosticResultId?: string,
            episodeGuid?: string,
            guid?: string,
            labOrderId?: string,
            labRequisitionsGuid?: string,
            requestResultId?: string,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/lab-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (diagnosticResultId !== undefined) {
                localVarQueryParameter["diagnostic_result_id"] = diagnosticResultId;
            }

            if (episodeGuid !== undefined) {
                localVarQueryParameter["episode__guid"] = episodeGuid;
            }

            if (guid !== undefined) {
                localVarQueryParameter["guid"] = guid;
            }

            if (labOrderId !== undefined) {
                localVarQueryParameter["lab_order_id"] = labOrderId;
            }

            if (labRequisitionsGuid !== undefined) {
                localVarQueryParameter["lab_requisitions__guid"] = labRequisitionsGuid;
            }

            if (requestResultId !== undefined) {
                localVarQueryParameter["request_result_id"] = requestResultId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabOrderRequest} [patchedLabOrderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderPartialUpdate: async (
            guid: string,
            patchedLabOrderRequest?: PatchedLabOrderRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labOrderPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/lab-order/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLabOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderRejectLabOrderCreate: async (guid: string, labOrderRequest: LabOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labOrderRejectLabOrderCreate", "guid", guid);
            // verify required parameter 'labOrderRequest' is not null or undefined
            assertParamExists("labOrderRejectLabOrderCreate", "labOrderRequest", labOrderRequest);
            const localVarPath = `/clinician-api/lab-order/{guid}/reject-lab-order`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labOrderRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/lab-order/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderUpdate: async (guid: string, labOrderRequest: LabOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labOrderUpdate", "guid", guid);
            // verify required parameter 'labOrderRequest' is not null or undefined
            assertParamExists("labOrderUpdate", "labOrderRequest", labOrderRequest);
            const localVarPath = `/clinician-api/lab-order/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LabOrderApi - functional programming interface
 * @export
 */
export const LabOrderApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LabOrderApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderCreate(
            labOrderRequest: LabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderCreate(labOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderFileAttachmentCreate(
            guid: string,
            labOrderRequest: LabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderFileAttachmentCreate(guid, labOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [diagnosticResultId]
         * @param {string} [episodeGuid]
         * @param {string} [guid]
         * @param {string} [labOrderId]
         * @param {string} [labRequisitionsGuid]
         * @param {string} [requestResultId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderList(
            diagnosticResultId?: string,
            episodeGuid?: string,
            guid?: string,
            labOrderId?: string,
            labRequisitionsGuid?: string,
            requestResultId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderList(
                diagnosticResultId,
                episodeGuid,
                guid,
                labOrderId,
                labRequisitionsGuid,
                requestResultId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabOrderRequest} [patchedLabOrderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderPartialUpdate(
            guid: string,
            patchedLabOrderRequest?: PatchedLabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderPartialUpdate(guid, patchedLabOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderRejectLabOrderCreate(
            guid: string,
            labOrderRequest: LabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderRejectLabOrderCreate(guid, labOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labOrderUpdate(
            guid: string,
            labOrderRequest: LabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labOrderUpdate(guid, labOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LabOrderApi - factory interface
 * @export
 */
export const LabOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabOrderApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderCreate(labOrderRequest: LabOrderRequest, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderCreate(labOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderFileAttachmentCreate(guid: string, labOrderRequest: LabOrderRequest, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderFileAttachmentCreate(guid, labOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [diagnosticResultId]
         * @param {string} [episodeGuid]
         * @param {string} [guid]
         * @param {string} [labOrderId]
         * @param {string} [labRequisitionsGuid]
         * @param {string} [requestResultId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderList(
            diagnosticResultId?: string,
            episodeGuid?: string,
            guid?: string,
            labOrderId?: string,
            labRequisitionsGuid?: string,
            requestResultId?: string,
            options?: any
        ): AxiosPromise<Array<LabOrder>> {
            return localVarFp
                .labOrderList(diagnosticResultId, episodeGuid, guid, labOrderId, labRequisitionsGuid, requestResultId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabOrderRequest} [patchedLabOrderRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderPartialUpdate(guid: string, patchedLabOrderRequest?: PatchedLabOrderRequest, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderPartialUpdate(guid, patchedLabOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderRejectLabOrderCreate(guid: string, labOrderRequest: LabOrderRequest, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderRejectLabOrderCreate(guid, labOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderRetrieve(guid: string, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labOrderUpdate(guid: string, labOrderRequest: LabOrderRequest, options?: any): AxiosPromise<LabOrder> {
            return localVarFp.labOrderUpdate(guid, labOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabOrderApi - object-oriented interface
 * @export
 * @class LabOrderApi
 * @extends {BaseAPI}
 */
export class LabOrderApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {LabOrderRequest} labOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderCreate(labOrderRequest: LabOrderRequest, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderCreate(labOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabOrderRequest} labOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderFileAttachmentCreate(guid: string, labOrderRequest: LabOrderRequest, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderFileAttachmentCreate(guid, labOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [diagnosticResultId]
     * @param {string} [episodeGuid]
     * @param {string} [guid]
     * @param {string} [labOrderId]
     * @param {string} [labRequisitionsGuid]
     * @param {string} [requestResultId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderList(
        diagnosticResultId?: string,
        episodeGuid?: string,
        guid?: string,
        labOrderId?: string,
        labRequisitionsGuid?: string,
        requestResultId?: string,
        options?: AxiosRequestConfig
    ) {
        return LabOrderApiFp(this.configuration)
            .labOrderList(diagnosticResultId, episodeGuid, guid, labOrderId, labRequisitionsGuid, requestResultId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {PatchedLabOrderRequest} [patchedLabOrderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderPartialUpdate(guid: string, patchedLabOrderRequest?: PatchedLabOrderRequest, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderPartialUpdate(guid, patchedLabOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabOrderRequest} labOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderRejectLabOrderCreate(guid: string, labOrderRequest: LabOrderRequest, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderRejectLabOrderCreate(guid, labOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderRetrieve(guid: string, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabOrderRequest} labOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabOrderApi
     */
    public labOrderUpdate(guid: string, labOrderRequest: LabOrderRequest, options?: AxiosRequestConfig) {
        return LabOrderApiFp(this.configuration)
            .labOrderUpdate(guid, labOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LabRequisitionApi - axios parameter creator
 * @export
 */
export const LabRequisitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabRequisitionCreateRequest} labRequisitionCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionCreate: async (labRequisitionCreateRequest: LabRequisitionCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labRequisitionCreateRequest' is not null or undefined
            assertParamExists("labRequisitionCreate", "labRequisitionCreateRequest", labRequisitionCreateRequest);
            const localVarPath = `/clinician-api/lab-requisition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labRequisitionCreateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionCreateLabOrderCreate: async (guid: string, labOrderRequest: LabOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labRequisitionCreateLabOrderCreate", "guid", guid);
            // verify required parameter 'labOrderRequest' is not null or undefined
            assertParamExists("labRequisitionCreateLabOrderCreate", "labOrderRequest", labOrderRequest);
            const localVarPath = `/clinician-api/lab-requisition/{guid}/create_lab_order`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labOrderRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {string} [labOrdersLabOrderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionList: async (episodeGuid?: string, labOrdersLabOrderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/lab-requisition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (episodeGuid !== undefined) {
                localVarQueryParameter["episode__guid"] = episodeGuid;
            }

            if (labOrdersLabOrderId !== undefined) {
                localVarQueryParameter["lab_orders__lab_order_id"] = labOrdersLabOrderId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabRequisitionResponseRequest} [patchedLabRequisitionResponseRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionPartialUpdate: async (
            guid: string,
            patchedLabRequisitionResponseRequest?: PatchedLabRequisitionResponseRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labRequisitionPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/lab-requisition/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLabRequisitionResponseRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labRequisitionRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/lab-requisition/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabRequisitionResponseRequest} labRequisitionResponseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionUpdate: async (
            guid: string,
            labRequisitionResponseRequest: LabRequisitionResponseRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labRequisitionUpdate", "guid", guid);
            // verify required parameter 'labRequisitionResponseRequest' is not null or undefined
            assertParamExists("labRequisitionUpdate", "labRequisitionResponseRequest", labRequisitionResponseRequest);
            const localVarPath = `/clinician-api/lab-requisition/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labRequisitionResponseRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LabRequisitionApi - functional programming interface
 * @export
 */
export const LabRequisitionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LabRequisitionApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabRequisitionCreateRequest} labRequisitionCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionCreate(
            labRequisitionCreateRequest: LabRequisitionCreateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabRequisitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionCreate(labRequisitionCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionCreateLabOrderCreate(
            guid: string,
            labOrderRequest: LabOrderRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabRequisitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionCreateLabOrderCreate(guid, labOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {string} [labOrdersLabOrderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionList(
            episodeGuid?: string,
            labOrdersLabOrderId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabRequisitionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionList(episodeGuid, labOrdersLabOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabRequisitionResponseRequest} [patchedLabRequisitionResponseRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionPartialUpdate(
            guid: string,
            patchedLabRequisitionResponseRequest?: PatchedLabRequisitionResponseRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabRequisitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionPartialUpdate(guid, patchedLabRequisitionResponseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabRequisitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabRequisitionResponseRequest} labRequisitionResponseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labRequisitionUpdate(
            guid: string,
            labRequisitionResponseRequest: LabRequisitionResponseRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabRequisitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labRequisitionUpdate(guid, labRequisitionResponseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LabRequisitionApi - factory interface
 * @export
 */
export const LabRequisitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabRequisitionApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabRequisitionCreateRequest} labRequisitionCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionCreate(labRequisitionCreateRequest: LabRequisitionCreateRequest, options?: any): AxiosPromise<LabRequisitionResponse> {
            return localVarFp.labRequisitionCreate(labRequisitionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabOrderRequest} labOrderRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionCreateLabOrderCreate(guid: string, labOrderRequest: LabOrderRequest, options?: any): AxiosPromise<LabRequisitionResponse> {
            return localVarFp.labRequisitionCreateLabOrderCreate(guid, labOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {string} [labOrdersLabOrderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionList(episodeGuid?: string, labOrdersLabOrderId?: string, options?: any): AxiosPromise<Array<LabRequisitionResponse>> {
            return localVarFp.labRequisitionList(episodeGuid, labOrdersLabOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabRequisitionResponseRequest} [patchedLabRequisitionResponseRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionPartialUpdate(
            guid: string,
            patchedLabRequisitionResponseRequest?: PatchedLabRequisitionResponseRequest,
            options?: any
        ): AxiosPromise<LabRequisitionResponse> {
            return localVarFp.labRequisitionPartialUpdate(guid, patchedLabRequisitionResponseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionRetrieve(guid: string, options?: any): AxiosPromise<LabRequisitionResponse> {
            return localVarFp.labRequisitionRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabRequisitionResponseRequest} labRequisitionResponseRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labRequisitionUpdate(guid: string, labRequisitionResponseRequest: LabRequisitionResponseRequest, options?: any): AxiosPromise<LabRequisitionResponse> {
            return localVarFp.labRequisitionUpdate(guid, labRequisitionResponseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabRequisitionApi - object-oriented interface
 * @export
 * @class LabRequisitionApi
 * @extends {BaseAPI}
 */
export class LabRequisitionApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {LabRequisitionCreateRequest} labRequisitionCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionCreate(labRequisitionCreateRequest: LabRequisitionCreateRequest, options?: AxiosRequestConfig) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionCreate(labRequisitionCreateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabOrderRequest} labOrderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionCreateLabOrderCreate(guid: string, labOrderRequest: LabOrderRequest, options?: AxiosRequestConfig) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionCreateLabOrderCreate(guid, labOrderRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [episodeGuid]
     * @param {string} [labOrdersLabOrderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionList(episodeGuid?: string, labOrdersLabOrderId?: string, options?: AxiosRequestConfig) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionList(episodeGuid, labOrdersLabOrderId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {PatchedLabRequisitionResponseRequest} [patchedLabRequisitionResponseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionPartialUpdate(
        guid: string,
        patchedLabRequisitionResponseRequest?: PatchedLabRequisitionResponseRequest,
        options?: AxiosRequestConfig
    ) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionPartialUpdate(guid, patchedLabRequisitionResponseRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionRetrieve(guid: string, options?: AxiosRequestConfig) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabRequisitionResponseRequest} labRequisitionResponseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabRequisitionApi
     */
    public labRequisitionUpdate(guid: string, labRequisitionResponseRequest: LabRequisitionResponseRequest, options?: AxiosRequestConfig) {
        return LabRequisitionApiFp(this.configuration)
            .labRequisitionUpdate(guid, labRequisitionResponseRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LabResultApi - axios parameter creator
 * @export
 */
export const LabResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultCreate: async (labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultFileAttachmentCreate: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultFileAttachmentCreate", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultFileAttachmentCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result/{guid}/file-attachment`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultInterpretationClinicalCreate: async (
            guid: string,
            labResultRequest: LabResultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultInterpretationClinicalCreate", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultInterpretationClinicalCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result/{guid}/interpretation/clinical`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultList: async (externalId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/lab-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (externalId !== undefined) {
                localVarQueryParameter["external_id"] = externalId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultPartialUpdate: async (
            guid: string,
            patchedLabResultRequest?: PatchedLabResultRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/lab-result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLabResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultReleaseCreate: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultReleaseCreate", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultReleaseCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result/{guid}/release`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultResolveOrphanedCreate: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultResolveOrphanedCreate", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultResolveOrphanedCreate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result/{guid}/resolve-orphaned`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/lab-result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultUpdate: async (guid: string, labResultRequest: LabResultRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultUpdate", "guid", guid);
            // verify required parameter 'labResultRequest' is not null or undefined
            assertParamExists("labResultUpdate", "labResultRequest", labResultRequest);
            const localVarPath = `/clinician-api/lab-result/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LabResultApi - functional programming interface
 * @export
 */
export const LabResultApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LabResultApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultCreate(
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultCreate(labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultFileAttachmentCreate(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultFileAttachmentCreate(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultInterpretationClinicalCreate(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultInterpretationClinicalCreate(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultList(
            externalId?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultList(externalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultPartialUpdate(
            guid: string,
            patchedLabResultRequest?: PatchedLabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultPartialUpdate(guid, patchedLabResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultReleaseCreate(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultReleaseCreate(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultResolveOrphanedCreate(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultResolveOrphanedCreate(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultUpdate(
            guid: string,
            labResultRequest: LabResultRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultUpdate(guid, labResultRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LabResultApi - factory interface
 * @export
 */
export const LabResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabResultApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultCreate(labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultCreate(labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultFileAttachmentCreate(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultFileAttachmentCreate(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultInterpretationClinicalCreate(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultInterpretationClinicalCreate(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultList(externalId?: string, options?: any): AxiosPromise<Array<LabResult>> {
            return localVarFp.labResultList(externalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultRequest} [patchedLabResultRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultPartialUpdate(guid: string, patchedLabResultRequest?: PatchedLabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultPartialUpdate(guid, patchedLabResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultReleaseCreate(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultReleaseCreate(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultResolveOrphanedCreate(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultResolveOrphanedCreate(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultRetrieve(guid: string, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultRequest} labResultRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultUpdate(guid: string, labResultRequest: LabResultRequest, options?: any): AxiosPromise<LabResult> {
            return localVarFp.labResultUpdate(guid, labResultRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabResultApi - object-oriented interface
 * @export
 * @class LabResultApi
 * @extends {BaseAPI}
 */
export class LabResultApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultCreate(labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultCreate(labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultFileAttachmentCreate(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultFileAttachmentCreate(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultInterpretationClinicalCreate(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultInterpretationClinicalCreate(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [externalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultList(externalId?: string, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultList(externalId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {PatchedLabResultRequest} [patchedLabResultRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultPartialUpdate(guid: string, patchedLabResultRequest?: PatchedLabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultPartialUpdate(guid, patchedLabResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultReleaseCreate(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultReleaseCreate(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultResolveOrphanedCreate(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultResolveOrphanedCreate(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultRetrieve(guid: string, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultRequest} labResultRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultApi
     */
    public labResultUpdate(guid: string, labResultRequest: LabResultRequest, options?: AxiosRequestConfig) {
        return LabResultApiFp(this.configuration)
            .labResultUpdate(guid, labResultRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * LabResultObservationApi - axios parameter creator
 * @export
 */
export const LabResultObservationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationCreate: async (
            labResultObservationRequest: LabResultObservationRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'labResultObservationRequest' is not null or undefined
            assertParamExists("labResultObservationCreate", "labResultObservationRequest", labResultObservationRequest);
            const localVarPath = `/clinician-api/lab-result-observation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultObservationRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/lab-result-observation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultObservationRequest} [patchedLabResultObservationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationPartialUpdate: async (
            guid: string,
            patchedLabResultObservationRequest?: PatchedLabResultObservationRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultObservationPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/lab-result-observation/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLabResultObservationRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultObservationRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/lab-result-observation/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationUpdate: async (
            guid: string,
            labResultObservationRequest: LabResultObservationRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("labResultObservationUpdate", "guid", guid);
            // verify required parameter 'labResultObservationRequest' is not null or undefined
            assertParamExists("labResultObservationUpdate", "labResultObservationRequest", labResultObservationRequest);
            const localVarPath = `/clinician-api/lab-result-observation/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(labResultObservationRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LabResultObservationApi - functional programming interface
 * @export
 */
export const LabResultObservationApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = LabResultObservationApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultObservationCreate(
            labResultObservationRequest: LabResultObservationRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultObservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultObservationCreate(labResultObservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultObservationList(
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabResultObservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultObservationList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultObservationRequest} [patchedLabResultObservationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultObservationPartialUpdate(
            guid: string,
            patchedLabResultObservationRequest?: PatchedLabResultObservationRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultObservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultObservationPartialUpdate(guid, patchedLabResultObservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultObservationRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultObservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultObservationRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async labResultObservationUpdate(
            guid: string,
            labResultObservationRequest: LabResultObservationRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabResultObservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.labResultObservationUpdate(guid, labResultObservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * LabResultObservationApi - factory interface
 * @export
 */
export const LabResultObservationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabResultObservationApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationCreate(labResultObservationRequest: LabResultObservationRequest, options?: any): AxiosPromise<LabResultObservation> {
            return localVarFp.labResultObservationCreate(labResultObservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationList(options?: any): AxiosPromise<Array<LabResultObservation>> {
            return localVarFp.labResultObservationList(options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {PatchedLabResultObservationRequest} [patchedLabResultObservationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationPartialUpdate(
            guid: string,
            patchedLabResultObservationRequest?: PatchedLabResultObservationRequest,
            options?: any
        ): AxiosPromise<LabResultObservation> {
            return localVarFp.labResultObservationPartialUpdate(guid, patchedLabResultObservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationRetrieve(guid: string, options?: any): AxiosPromise<LabResultObservation> {
            return localVarFp.labResultObservationRetrieve(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {LabResultObservationRequest} labResultObservationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        labResultObservationUpdate(guid: string, labResultObservationRequest: LabResultObservationRequest, options?: any): AxiosPromise<LabResultObservation> {
            return localVarFp.labResultObservationUpdate(guid, labResultObservationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabResultObservationApi - object-oriented interface
 * @export
 * @class LabResultObservationApi
 * @extends {BaseAPI}
 */
export class LabResultObservationApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {LabResultObservationRequest} labResultObservationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultObservationApi
     */
    public labResultObservationCreate(labResultObservationRequest: LabResultObservationRequest, options?: AxiosRequestConfig) {
        return LabResultObservationApiFp(this.configuration)
            .labResultObservationCreate(labResultObservationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultObservationApi
     */
    public labResultObservationList(options?: AxiosRequestConfig) {
        return LabResultObservationApiFp(this.configuration)
            .labResultObservationList(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {PatchedLabResultObservationRequest} [patchedLabResultObservationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultObservationApi
     */
    public labResultObservationPartialUpdate(
        guid: string,
        patchedLabResultObservationRequest?: PatchedLabResultObservationRequest,
        options?: AxiosRequestConfig
    ) {
        return LabResultObservationApiFp(this.configuration)
            .labResultObservationPartialUpdate(guid, patchedLabResultObservationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultObservationApi
     */
    public labResultObservationRetrieve(guid: string, options?: AxiosRequestConfig) {
        return LabResultObservationApiFp(this.configuration)
            .labResultObservationRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {LabResultObservationRequest} labResultObservationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabResultObservationApi
     */
    public labResultObservationUpdate(guid: string, labResultObservationRequest: LabResultObservationRequest, options?: AxiosRequestConfig) {
        return LabResultObservationApiFp(this.configuration)
            .labResultObservationUpdate(guid, labResultObservationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {MessageCreateRequest} messageCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreate: async (messageCreateRequest: MessageCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageCreateRequest' is not null or undefined
            assertParamExists("messageCreate", "messageCreateRequest", messageCreateRequest);
            const localVarPath = `/clinician-api/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(messageCreateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageList: async (episodeGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (episodeGuid !== undefined) {
                localVarQueryParameter["episode__guid"] = episodeGuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("messageRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/message/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {MessageCreateRequest} messageCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageCreate(
            messageCreateRequest: MessageCreateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageCreate(messageCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageList(
            episodeGuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageWithAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageList(episodeGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messageRetrieve(guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messageRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {MessageCreateRequest} messageCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreate(messageCreateRequest: MessageCreateRequest, options?: any): AxiosPromise<Message> {
            return localVarFp.messageCreate(messageCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageList(episodeGuid?: string, options?: any): AxiosPromise<Array<MessageWithAttachment>> {
            return localVarFp.messageList(episodeGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageRetrieve(guid: string, options?: any): AxiosPromise<Message> {
            return localVarFp.messageRetrieve(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {MessageCreateRequest} messageCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messageCreate(messageCreateRequest: MessageCreateRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration)
            .messageCreate(messageCreateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [episodeGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messageList(episodeGuid?: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration)
            .messageList(episodeGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public messageRetrieve(guid: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration)
            .messageRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAllergyIntoleranceList: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("patientAllergyIntoleranceList", "guid", guid);
            const localVarPath = `/clinician-api/patient/{guid}/allergyIntolerance`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter patients by different fields using the body of the request to pass the parameters and not expose they in the URL. Body example:     episode_guids: patients with episodes that have the given guids :param request: :return:
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientFilterUpdate: async (patientRequest: PatientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientRequest' is not null or undefined
            assertParamExists("patientFilterUpdate", "patientRequest", patientRequest);
            const localVarPath = `/clinician-api/patient/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatchedPatientUpdateRequest} [patchedPatientUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPartialUpdate: async (
            guid: string,
            patchedPatientUpdateRequest?: PatchedPatientUpdateRequest,
            options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("patientPartialUpdate", "guid", guid);
            const localVarPath = `/clinician-api/patient/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPatientUpdateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSearchRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/patient/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUpdate: async (guid: string, patientRequest: PatientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("patientUpdate", "guid", guid);
            // verify required parameter 'patientRequest' is not null or undefined
            assertParamExists("patientUpdate", "patientRequest", patientRequest);
            const localVarPath = `/clinician-api/patient/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(patientRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientAllergyIntoleranceList(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientAllergyIntolerance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientAllergyIntoleranceList(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Filter patients by different fields using the body of the request to pass the parameters and not expose they in the URL. Body example:     episode_guids: patients with episodes that have the given guids :param request: :return:
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientFilterUpdate(
            patientRequest: PatientRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientFilterUpdate(patientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatchedPatientUpdateRequest} [patchedPatientUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientPartialUpdate(
            guid: string,
            patchedPatientUpdateRequest?: PatchedPatientUpdateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientPartialUpdate(guid, patchedPatientUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientSearchRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientSearchRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientUpdate(
            guid: string,
            patientRequest: PatientRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientUpdate(guid, patientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration);
    return {
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientAllergyIntoleranceList(guid: string, options?: any): AxiosPromise<Array<PatientAllergyIntolerance>> {
            return localVarFp.patientAllergyIntoleranceList(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter patients by different fields using the body of the request to pass the parameters and not expose they in the URL. Body example:     episode_guids: patients with episodes that have the given guids :param request: :return:
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientFilterUpdate(patientRequest: PatientRequest, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientFilterUpdate(patientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatchedPatientUpdateRequest} [patchedPatientUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientPartialUpdate(guid: string, patchedPatientUpdateRequest?: PatchedPatientUpdateRequest, options?: any): AxiosPromise<PatientUpdate> {
            return localVarFp.patientPartialUpdate(guid, patchedPatientUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSearchRetrieve(options?: any): AxiosPromise<Patient> {
            return localVarFp.patientSearchRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
         * @param {string} guid
         * @param {PatientRequest} patientRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUpdate(guid: string, patientRequest: PatientRequest, options?: any): AxiosPromise<Patient> {
            return localVarFp.patientUpdate(guid, patientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientAllergyIntoleranceList(guid: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration)
            .patientAllergyIntoleranceList(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter patients by different fields using the body of the request to pass the parameters and not expose they in the URL. Body example:     episode_guids: patients with episodes that have the given guids :param request: :return:
     * @param {PatientRequest} patientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientFilterUpdate(patientRequest: PatientRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration)
            .patientFilterUpdate(patientRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
     * @param {string} guid
     * @param {PatchedPatientUpdateRequest} [patchedPatientUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientPartialUpdate(guid: string, patchedPatientUpdateRequest?: PatchedPatientUpdateRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration)
            .patientPartialUpdate(guid, patchedPatientUpdateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientSearchRetrieve(options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration)
            .patientSearchRetrieve(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces UpdateModelMixin inheritance. If perform_update is overridden during implementation, make sure to call and return AuditedUpdateModelMixin.perform_update
     * @param {string} guid
     * @param {PatientRequest} patientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public patientUpdate(guid: string, patientRequest: PatientRequest, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration)
            .patientUpdate(guid, patientRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * VerifyApi - axios parameter creator
 * @export
 */
export const VerifyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {VerificationClaimCreateRequest} verificationClaimCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCreate: async (verificationClaimCreateRequest: VerificationClaimCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verificationClaimCreateRequest' is not null or undefined
            assertParamExists("verifyCreate", "verificationClaimCreateRequest", verificationClaimCreateRequest);
            const localVarPath = `/clinician-api/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(verificationClaimCreateRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyList: async (episodeGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clinician-api/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (episodeGuid !== undefined) {
                localVarQueryParameter["episode__guid"] = episodeGuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRetrieve: async (guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists("verifyRetrieve", "guid", guid);
            const localVarPath = `/clinician-api/verify/{guid}`.replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VerifyApi - functional programming interface
 * @export
 */
export const VerifyApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VerifyApiAxiosParamCreator(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {VerificationClaimCreateRequest} verificationClaimCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyCreate(
            verificationClaimCreateRequest: VerificationClaimCreateRequest,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationClaim>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyCreate(verificationClaimCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyList(
            episodeGuid?: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerificationClaim>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyList(episodeGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyRetrieve(
            guid: string,
            options?: AxiosRequestConfig
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationClaim>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRetrieve(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * VerifyApi - factory interface
 * @export
 */
export const VerifyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerifyApiFp(configuration);
    return {
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {VerificationClaimCreateRequest} verificationClaimCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCreate(verificationClaimCreateRequest: VerificationClaimCreateRequest, options?: any): AxiosPromise<VerificationClaim> {
            return localVarFp.verifyCreate(verificationClaimCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} [episodeGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyList(episodeGuid?: string, options?: any): AxiosPromise<Array<VerificationClaim>> {
            return localVarFp.verifyList(episodeGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
         * @param {string} guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRetrieve(guid: string, options?: any): AxiosPromise<VerificationClaim> {
            return localVarFp.verifyRetrieve(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerifyApi - object-oriented interface
 * @export
 * @class VerifyApi
 * @extends {BaseAPI}
 */
export class VerifyApi extends BaseAPI {
    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {VerificationClaimCreateRequest} verificationClaimCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyApi
     */
    public verifyCreate(verificationClaimCreateRequest: VerificationClaimCreateRequest, options?: AxiosRequestConfig) {
        return VerifyApiFp(this.configuration)
            .verifyCreate(verificationClaimCreateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} [episodeGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyApi
     */
    public verifyList(episodeGuid?: string, options?: AxiosRequestConfig) {
        return VerifyApiFp(this.configuration)
            .verifyList(episodeGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces CreateModelMixin inheritance. If perform_create is overridden during implementation, make sure to call and return AuditCreateModelMixin.perform_create
     * @param {string} guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyApi
     */
    public verifyRetrieve(guid: string, options?: AxiosRequestConfig) {
        return VerifyApiFp(this.configuration)
            .verifyRetrieve(guid, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
