import axios from "axios";
import helpers from "../functions/helpers";
import { ClinicianApi as ClinicianApiDef, ConsultApi, PhonecallApi, ProgramApi, ReasonApi } from "./consult-api";
import { EncounterApi, EpisodeApi, IntakeApi, MessageApi, PatientApi, VerifyApi } from "./emr-api";
import { SummaryRequestApi, SummaryResultApi } from "./tinkerbell-api";

export class ClinicianApi {
    consult: ConsultApi;
    program: ProgramApi;
    intake: IntakeApi;
    episode: EpisodeApi;
    patient: PatientApi;
    encounter: EncounterApi;
    message: MessageApi;
    verify: VerifyApi;
    clinician: ClinicianApiDef;
    phonecall: PhonecallApi;
    reason: ReasonApi;
    summaryRequest: SummaryRequestApi;
    summaryResult: SummaryResultApi;

    constructor(req?: any) {
        /* Pass in req for server side requests, we need to extract the JWT from the session cookie */
        let headers = {};
        let base_path = { CONSULT: "", EMR: "", TINKERBELL: "" };
        if (req) {
            headers = helpers.getAuthorization(req);
            base_path.CONSULT = process.env.CONSULT_SERVICE_URL as string;
            base_path.EMR = process.env.EMR_SERVICE_URL as string;
            base_path.TINKERBELL = process.env.TINKERBELL_SERVICE_URL as string;
        } else {
            base_path.CONSULT = window.location.origin;
            base_path.EMR = window.location.origin;
            base_path.TINKERBELL = window.location.origin;
        }
        this.consult = new ConsultApi(undefined, base_path.CONSULT, axios.create({ headers }));
        this.program = new ProgramApi(undefined, base_path.CONSULT, axios.create({ headers }));
        this.intake = new IntakeApi(undefined, base_path.EMR, axios.create({ headers }));
        this.episode = new EpisodeApi(undefined, base_path.EMR, axios.create({ headers }));
        this.patient = new PatientApi(undefined, base_path.EMR, axios.create({ headers }));
        this.encounter = new EncounterApi(undefined, base_path.EMR, axios.create({ headers }));
        this.message = new MessageApi(undefined, base_path.EMR, axios.create({ headers }));
        this.verify = new VerifyApi(undefined, base_path.EMR, axios.create({ headers }));
        this.clinician = new ClinicianApiDef(undefined, base_path.CONSULT, axios.create({ headers }));
        this.phonecall = new PhonecallApi(undefined, base_path.CONSULT, axios.create({ headers }));
        this.reason = new ReasonApi(undefined, base_path.CONSULT, axios.create({ headers }));
        this.summaryRequest = new SummaryRequestApi(undefined, base_path.TINKERBELL, axios.create({ headers }));
        this.summaryResult = new SummaryResultApi(undefined, base_path.TINKERBELL, axios.create({ headers }));
    }
}
